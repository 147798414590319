<template>
  <v-app>
    <!-- <nav style="background-color:#0F5288;height:50px" class="pa-2 ">
		<v-layout >
			<v-flex>
				<b style="color:white;" class="titleDate">Rendez-vous du <vue-typer text=' 23 AU 2 FEBRUARY 2022' ></vue-typer></b>
			</v-flex>
			<v-flex style="text-align:right">
				<a href="https://ascrea.ma/" target="_blank" style="text-decoration:none;" class="contactAdn titleDateAs ">Réalisé par ascrea.ma</a><br>
			</v-flex>
		</v-layout>
	</nav> -->
  <nav
      style="background-color:white"
      class="navbar navbar-light navbar-expand-md bg-faded justify-content-center sticky-top "
    >
      <a href="#" class="d-flex  mr-auto">
        <img class="img-responsive logonav" src="/logoamarc.png" />
      </a>
      <button
        class="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#collapsingNavbar3"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="navbar-collapse collapse w-100" id="collapsingNavbar3">
        <ul class="nav navbar-nav  ml-auto justify-content-center first">
          <li>
            <a href="#propos">
              <b>PRESENTATION DE L'EVENEMENT</b>
            </a>
          </li>
          <li>
            <a href="#programme">
              <b>PROGRAMME OFFICIEL</b>
            </a>
          </li>
          <li>
            <a href="#sponsor">
              <b> NOS SPONSORS</b>
            </a>
          </li>
          <!-- <li>
            <a href="#sponsors">
              <b>CANEVAS</b>
            </a>
          </li> -->
          
          <li
            class="LGactive ml-md-3"
            style="background-color:#3e90c0!important;"
          >
             <a href="http://vodamarc.hashtagsante.com/" target="_blanc">
              <b style="color:white">VOD</b>
            </a>
          </li>
          <!-- <li
            class="LGactive ml-md-3"
            style="background-color:#E30525!important;"
          >
             <a href="http://stream.hashtagsante.ma/" target="_blanc">
              <b style="color:white">HTIC Stream</b>
            </a>
          </li> -->
        </ul>
      </div>
      <!-- <v-dialog v-model="dialog" width="500">
			<v-card>
				<v-card-title class="text-h5 ">
					Cher visiteur
				</v-card-title>
				<v-col>
					La VOD sera bientôt disponible
				</v-col>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn  color="bleu"  text  @click="dialog = false">
						Ferme
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog> -->
    </nav>


    <div data-spy="scroll" data-target="#navbar-app" data-offset="0">
      <div id="accueil">
        <accueil />
      </div>
      <!-- <div id="Countdown">
        <Countdown class="mt-5 " />
      </div> -->
      <div>
        <timer />
      </div>
      <div id="apropos2023">
        <apropos2023 class="mt-5" v-on:slidenum="slidenum" />
      </div>
      
      <div id="propos">
        <propos />
      </div>

      <div id="presentation">
        <presentation />
      </div>
    
      <div id="contact">
        <contact />
      </div>
      <div id="thematique">
        <thematique class="mt-5" v-on:slidenum="slidenum" />
      </div>
      <div id="scientifique">
        <scientifique class="mt-5 " />
      </div>
      <div id="inscription">
        <inscription class="mt-5 " />
      </div>
      <div id="programme">
        <programme class="mt-5 " />
      </div>
      <div id="actualitetitle">
        <actualitetitle class="mt-5 " />
      </div>
      <div id="actualite">
        <actualite class="mt-5 " />
      </div>

     
      <div id="speakers">
        <speakers class="mt-5" :slideshow="slideshow" />
      </div>
      <div id="tab">
        <axe2 class="mt-10" />
      </div>
      <div id="sponsors">
        <sponsors class="mt-5 " />
      </div>
     
      <div id="sponsors">
        <sponsors class="mt-5 " />
      </div>
    
      <div id="canevastitle">
        <canevastitle class="mt-5 " />
      </div>
      <div id="canevas">
        <canevas  class="mt-5 " />
      </div>
      <div id="carte">
        <carte class="mt-5 " />
      </div>
      <div id="sponsor">
        <sponsor class="mt-5 " />
      </div>
      
    
      <div id="footpara">
        <footpara class="mt-5 " />
      </div>
   
    </div>
    <!-- <div id="social" class="social" style="text-align:right">
      <v-card
        class="flex pa-1 mb-2"
        max-width="40px"
        color="white"
        href="https://web.facebook.com/htic2022"
        target="_blank"
      >
        <a href="https://web.facebook.com/htic2022" target="_blank">
          <img
            height="29"
            width="29"
            class="img-responsive mb-1"
            src="../assets/F.png"
          />
        </a>
      </v-card>
      <v-card
        class="flex pa-1"
        max-width="40px"
        color="white"
        href="https://www.linkedin.com/company/htic-2023-by-morocco-sim/?viewAsMember=true"
        target="_blank"
      >
        <a
          href="https://www.linkedin.com/company/htic-2023-by-morocco-sim/?viewAsMember=true"
          target="_blank"
        >
          <img
            height="29"
            width="29"
            class="img-responsive mb-1"
            src="../assets/L.png"
          />
        </a>
      </v-card>
    </div> -->
    <v-parallax src="../assets/footer.jpg" class="footpara" id="footpara">
      <v-row>
        <v-col cols="12" md="4" sm="12" align="center" class="mt-5 ">
          <br /><br /><a href="#"
            ><img
              class="img-responsive Logofoter"
              src="../assets/logoblanc.png"
              height="100"
              width="280"
          /></a>
        </v-col>
        <v-col cols="12" md="8" sm="12" class="mt-sm-15 infofooter"
          ><br /><br />
          <h2><b style="color:#E4A837">SECRÉTARIAT DU CONGRÈS</b></h2>
          <!-- <b style="color:#E4A837">POUR PLUS D'INFORMATIONS SUR L'INSCRIPTION</b><br> -->
          <b>Mme Nouhaila Ibnoughafir :</b>
          <a
            href="tel:+212660442963"
            class="contactAdn"
            style="text-decoration:none;"
            >+212 660 44 29 63</a
          >
          &nbsp;/&nbsp;<a
            href="mailto:nouhaila.ibnoughafir@hashtagsante.ma"
            class="contactAdn"
            style="text-decoration:none;"
            >nouhaila.ibnoughafir@hashtagsante.ma</a
          ><br /><br />
          <b>Mr Khalil Sibari :</b>
          <a
            href="tel:+212602820075"
            class="contactAdn"
            style="text-decoration:none;"
            >+212 602 82 00 75</a
          >/
          <a
            href="mailto:khalil.sibari@hashtagsante.ma"
            class="contactAdn"
            style="text-decoration:none;"
            >khalil.sibari@hashtagsante.ma</a
          ><br />
          <!-- <b style="color:#E4A837">POUR PLUS D'INFORMATIONS SUR LES STANDS</b><br>
					Mme Nouhaila Ibnoughafir : <a href="tel:+212660442963" class="contactAdn" style="text-decoration:none;">+212 660 44 29 63</a>
					- E-mail : <a href="mailto:nouhaila.ibnoughafir@hashtagsante.ma" class="contactAdn" style="text-decoration:none;">nouhaila.ibnoughafir@hashtagsante.ma</a><br>
					Mme Sara Kenas :
					<a href="tel:+212660575908" style="text-decoration:none;" class="contactAdn">+ 212 660 57 59 08</a>
					- E-mail : <a href="mailto:sara.kenas@hashtagsante.ma" class="contactAdn" style="text-decoration:none;">sara.kenas@hashtagsante.ma</a><br> -->
        </v-col>
        <v-col cols="12" md="12" sm="12" align="center">
          <a href="https://ascrea.ma/" target="_blanc"
            ><img class="img-responsive " src="../assets/logo.png" width="200"
          /></a>
        </v-col>
      </v-row>
    </v-parallax>
  </v-app>
</template>

<script>
//  import inscription from './landing/inscription.vue';
import Propos from "./landing/propos.vue";
// import thematique from "./landing/thematique.vue";
import accueil from "./landing/accueil.vue";
// import timer from "./landing/timer.vue";
// import actualite from './landing/actualite.vue';
// import scientifique from "./landing/scientifique.vue";
// import actualitetitle from './landing/actualitetitle.vue';
 import programme from './landing/programme.vue';
// import axe2 from "./landing/axe2.vue";
// import carte from "./landing/carte.vue";
// import apropos2023 from './landing/apropos2023.vue';
// import canevas from './landing/canevas.vue';
// import canevastitle from './landing/canevastitle.vue';
import sponsor from './landing/sponsor.vue';
// import Countdown from "./landing/Countdown.vue";


export default {
  // components: {  scientifique ,},
  // components: { thematique, Propos,axe2,accueil,timer },
    components: {  Propos,accueil,programme,sponsor,},

  data: () => ({
    scrollPosition: null,
    slideshow: "",
    dialog: false,
    vid: "",
    audio: true,
  }),
  methods: {
    updateScroll() {
      this.scrollPosition = window.scrollY;
    },
    goTo(path) {
      this.$router.push(path);
    },
    click: function() {
      this.$refs.inscriComponent.setValue();
    },

    slidenum(num) {
      this.slideshow = num;
    },
  },
  mounted() {
    window.addEventListener("scroll", this.updateScroll);

    this.slideshow = 0;
  },
};
</script>
<style>
html {
  scroll-behavior: smooth;
}
/*.accueil{
  background-image:url('/assets/health.jpg');
  background-repeat:no-repeat;
  background-size:contain;
  background-position:center;
}*/
.nav{
  /* border-top:4px solid rgb(4, 0, 255); */
  /* height: 10px; */
 }
 .img-responsive.logonav{
  height: 60px;
  width:60px;

 }
.first li a {
  color: #000 !important;
  transition: 0.3s;
  font-size: 14px !important;
}
.first li a:hover {
  color: white !important;
  background-color: #0c3d58 !important;
}

.LGactive li a {
  color: white !important;
  background-color: #3e90c0 !important;
  padding: 5px;
}
.LGactive li a:hover {
  background-color: #0c3d58 !important;
}
.logonav {
  width: 250px;
}
.footpara {
  height: 450px !important;
}
.footparashad {
  background-color: rgba(15, 132, 168, 0.877);
  height: 400px !important;
}
.contactAdn {
  color: white !important;
}
a:hover {
  color: #e4a837 !important;
}

.titleDateAs {
  font-size: 15px;
}

.infofooter {
  font-family: "Montserrat", sans-serif;
}

@media (min-width: 1166px) and (max-width: 1361px) {
  .first li a {
    font-size: 14px !important;
  }
}

@media (min-width: 768px) and (max-width: 1165px) {
  .first li a {
    font-size: 10px !important;
  }

  .logonav {
    width: 175px !important;
  }
}

/* Portrait tablet to landscape and desktop */
@media (min-width: 768px) and (max-width: 979px) {
  .logonav {
    width: 190px;
  }

  .footpara {
    height: 650px !important;
  }
}

/* Landscape phone to portrait tablet */
@media (max-width: 767px) {
  .inscriBtn {
    width: 130px;
  }
  .liveBtn {
    width: 250px;
  }
  .text {
    font-size: 3vw;
    text-justify: distribute;
  }
  .Logofoter {
    width: 250px;
  }
  .logonav {
    width: 150px;
  }
  .footpara {
    height: 645px !important;
  }
  .footparashad {
    height: 650px !important;
  }
  .infofooter {
    font-size: 14px;
    padding-left: 25px !important;
    padding-right: 17px !important;
  }
}

.footer {
  margin-top: 100px;
}
.footText {
  color: white;
  text-align: right;
  font-size: 0.55em;
  color: #fff;
  text-align: right;
  font-family: "Montserrat", sans-serif;
}
v-img.sponsor {
  position: relative;
}
.footTitle {
  text-align: left;
  font-family: "Montserrat", sans-serif;
  font-size: 1rem;
  font-weight: 500;
}
.titlefootline {
  content: "";
  display: block;
  width: 4.375rem;
  height: 0.25rem;
  background-color: red;
  bottom: 0;
  right: 0;
}
.social {
  position: absolute;
  right: 0px;
  position: fixed;
  width: 50px;
  top: 30%;
  /* z-index: 9; */
}
.navbar {
  margin-bottom: 0px !important;
  z-index: 3 !important;
}
.inscriBtn {
  background-color: #fbb400 !important;
}
.liveBtn {
  background-color: #ff0800 !important;
  color: white !important;
}

.liveBtn {
  color: #ffff !important;
}
.vue-typer .custom.char.typed {
  color: #f19840;
  font-size: 20px;
}
.titleDate {
  font-size: 20px;
}
/* Landscape phone to portrait tablet */
@media (max-width: 767px) {
  .vue-typer .custom.char.typed {
    font-size: 10px;
  }
  .titleDate {
    font-size: 10px;
  }
  .titleDateAs {
    font-size: 10px;
  }

  .liveBtn {
    margin-bottom: 5px !important;
  }
}
</style>
