import { render, staticRenderFns } from "./propos.vue?vue&type=template&id=048e5c83&scoped=true&"
import script from "./propos.vue?vue&type=script&lang=js&"
export * from "./propos.vue?vue&type=script&lang=js&"
import style0 from "./propos.vue?vue&type=style&index=0&id=048e5c83&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "048e5c83",
  null
  
)

export default component.exports