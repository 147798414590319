<template >
    <v-app class="agendaback">
        <v-container>
        <v-card>
            <div>
                <div class="p-2">
                    <v-img  src="logo.png"  max-width="300" ></v-img>
                </div>
                <div class="date p-2">
                    <strong>JEUDI 24 FÉVRIER 2022</strong>
                </div>
                <v-timeline align-top dense class="timeline">
                    <v-timeline-item color="blue" small>
                        <v-row class="pt-1 timelineprog">
                            <v-col cols="8">
                                <div class="heurire p-1">09H30 - 11H00</div>
                            </v-col>
                            <v-col cols="12">    
                                <v-row>
                                    <v-col cols="12">
                                        <div>
                                            <span style="color:#E4A837"> Track 1 </span><span style="color:#E4A837" class="sName">Conférences Updates I</span>
                                            <br>
                                            <span style="color:#204563;white-space: pre-line;" class="sName">Starting simulation program to international excellence</span><br>
                                            <a href="#speakers" @click="slideTo(13)" style="text-decoration:none">
                                                <span class="ml-2 orateurs">A. Boker, Jeddah, Arabie Saudite</span>
                                            </a><br>
                                            <span style="color:#204563;white-space: pre-line;" class="sName">Toward smart simulated hospitals</span><br>
                                            <a href="#speakers" @click="slideTo(13)" style="text-decoration:none">
                                                <span class="ml-2 orateurs">Y. Zidoun, Dubai, Émirates Arabes Unies |</span>
                                            </a><br>
                                            <span style="color:#204563;white-space: pre-line;" class="sName">La simulation interprofessionnelle au service de santé : où en-sommes-nous ?</span><br>
                                            <a href="#speakers" @click="slideTo(13)" style="text-decoration:none">
                                                <span class="ml-2 orateurs"> L. Belyamani, Rabat, Maroc</span>
                                            </a><br>
                                            <hr class="hr3">
                                        </div>
                                        <div>
                                            <span style="color:#E4A837"> Track 2 </span><span style="color:#E4A837" class="sName">Serious game</span>
                                            <br>
                                            <span style="color:#204563;white-space: pre-line;" class="sName">HemoSims Trauma</span><br>
                                            <a href="#speakers" @click="slideTo(13)" style="text-decoration:none">
                                                <span class="ml-2 orateurs">M. Faroudy, Rabat, Maroc |</span>
                                            </a>
                                            <a href="#speakers" @click="slideTo(13)" style="text-decoration:none">
                                                <span class="ml-2 orateurs"> T. Abouelhassan, Marrakech, Maroc</span>
                                            </a>
                                            <hr class="hr3">
                                        </div>
                                        <div>
                                            <span style="color:#E4A837"> Track 3 </span><span style="color:#E4A837" class="sName">Procedural</span>
                                            <br>
                                            <span style="color:#204563;white-space: pre-line;" class="sName">Auscultation cardiaque</span><br>
                                            <a href="#speakers" @click="slideTo(13)" style="text-decoration:none">
                                                <span class="ml-2 orateurs">Z. Raissouni, Tanger, Maroc</span>
                                            </a>
                                            <hr class="hr3">
                                        </div>
                                        <div>
                                            <span style="color:#E4A837"> Track 4 </span><span style="color:#E4A837" class="sName">Procedural</span>
                                            <br>
                                            <span style="color:#204563;white-space: pre-line;" class="sName">Curriculum Accouchement</span><br>
                                            <a href="#speakers" @click="slideTo(13)" style="text-decoration:none">
                                                <span class="ml-2 orateurs">A. Mimouni, Oujda, Maroc |</span>
                                            </a>
                                            <a href="#speakers" @click="slideTo(13)" style="text-decoration:none">
                                                <span class="ml-2 orateurs"> H. Taheri, Oujda, Maroc</span>
                                            </a>
                                            <hr class="hr3">
                                        </div>
                                        <div>
                                            <span style="color:#E4A837"> Track 5 </span><span style="color:#E4A837" class="sName">Interprofessionnel</span>
                                            <br>
                                            <span style="color:#204563;white-space: pre-line;" class="sName">Curriculum Soft skills ou comment améliorer ses compétences non techniques Part I</span><br>
                                            <a href="#speakers" @click="slideTo(13)" style="text-decoration:none">
                                                <span class="ml-2 orateurs">M. Fourtassi, Tanger, Maroc |</span>
                                            </a>
                                            <a href="#speakers" @click="slideTo(13)" style="text-decoration:none">
                                                <span class="ml-2 orateurs"> M. El Mouhajir, Rabat, Maroc</span>
                                            </a>
                                            <hr class="hr3">
                                        </div>
                                        <div>
                                            <span style="color:#E4A837"> Track 6 </span><span style="color:#E4A837" class="sName">Haptic</span>
                                            <br>
                                            <span style="color:#204563;white-space: pre-line;" class="sName">Curriculum Coelioscopie en chirurgie viscérale Part I</span><br>
                                            <a href="#speakers" @click="slideTo(13)" style="text-decoration:none">
                                                <span class="ml-2 orateurs">H. Fennane, Marrakech, Maroc |</span>
                                            </a>
                                            <a href="#speakers" @click="slideTo(13)" style="text-decoration:none">
                                                <span class="ml-2 orateurs"> M. Soufi, Agadir, Maroc</span>
                                            </a><br>
                                            <span style="color:#204563;white-space: pre-line;" class="sName">Curriculum Endoscopie digestive Part I</span><br>
                                            <a href="#speakers" @click="slideTo(13)" style="text-decoration:none">
                                                <span class="ml-2 orateurs">N. Aqodad, Agadir, Maroc |</span>
                                            </a>
                                            <a href="#speakers" @click="slideTo(13)" style="text-decoration:none">
                                                <span class="ml-2 orateurs"> E. Maidi, Agadir, Maroc</span>
                                            </a>
                                            <hr class="hr3">
                                        </div>
                                        <div>
                                            <span style="color:#E4A837"> Track 7 </span><span style="color:#E4A837" class="sName">Ultrasound</span>
                                            <br>
                                            <span style="color:#204563;white-space: pre-line;" class="sName">Curriculum Échographie hépato-biliaire Part I</span><br>
                                            <a href="#speakers" @click="slideTo(13)" style="text-decoration:none">
                                                <span class="ml-2 orateurs">N. Touil, Casablanca, Maroc |</span>
                                            </a>
                                            <a href="#speakers" @click="slideTo(13)" style="text-decoration:none">
                                                <span class="ml-2 orateurs"> H. Tabakh, Casablanca, Maroc</span>
                                            </a>
                                            <hr class="hr3">
                                        </div>
                                        <div>
                                            <span style="color:#E4A837"> Track 8 </span><span style="color:#E4A837" class="sName">Track 8 Sim Galaxy 360°</span>
                                            <br>
                                            <span style="color:#204563;white-space: pre-line;" class="sName">Scenarii Urgences médicales Part I</span><br>
                                            <a href="#speakers" @click="slideTo(13)" style="text-decoration:none">
                                                <span class="ml-2 orateurs">O. Rabbai, Agadir, Maroc |</span>
                                            </a>
                                            <a href="#speakers" @click="slideTo(13)" style="text-decoration:none">
                                                <span class="ml-2 orateurs"> B. Armel, Salé, Maroc |</span>
                                            </a>
                                            <a href="#speakers" @click="slideTo(13)" style="text-decoration:none">
                                                <span class="ml-2 orateurs"> L. Yara, Laayoune, Maroc</span>
                                            </a>
                                            <hr class="hr3">
                                        </div>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                    </v-timeline-item>

                    <v-timeline-item color="blue" small>
                        <v-row class="pt-1 timelineprog">
                            <v-col cols="8">
                                <div class="heurire p-1">11H30 - 13H00</div>
                            </v-col>
                            <v-col cols="12">    
                                <div>
                                    <span style="color:#E4A837"> Track 1 </span><span style="color:#E4A837" class="sName">Symposia</span>
                                    <br>
                                    <span style="color:#204563;white-space: pre-line;" class="sName">
                                        High technology and its benefits for Healthcare Learning Team CAE
                                    </span><br>
                                    <a href="#speakers" @click="slideTo(13)" style="text-decoration:none">
                                        <span class="ml-2 orateurs">Orateur 1</span>
                                    </a><br>
                                    <span style="color:#204563;white-space: pre-line;" class="sName">
                                        How tramaucare can benefit from telesimulation ? Team Pro-EMS
                                    </span><br>
                                    <a href="#speakers" @click="slideTo(13)" style="text-decoration:none">
                                        <span class="ml-2 orateurs">Orateur 2</span>
                                    </a>
                                    <hr class="hr3">
                                </div>
                                <div>
                                    <span style="color:#E4A837"> Track 2 </span><span style="color:#E4A837" class="sName">Serious game</span>
                                    <br>
                                    <span style="color:#204563;white-space: pre-line;" class="sName">HemoSims Hémorragie du post-partum</span><br>
                                    <a href="#speakers" @click="slideTo(13)" style="text-decoration:none">
                                        <span class="ml-2 orateurs">AR. El Adib, Marrakech, Maroc |</span>
                                    </a>
                                    <a href="#speakers" @click="slideTo(13)" style="text-decoration:none">
                                        <span class="ml-2 orateurs"> B. Housni, Oujda, Maroc</span>
                                    </a>
                                    <hr class="hr3">
                                </div>
                                <div>
                                    <span style="color:#E4A837"> Track 3 </span><span style="color:#E4A837" class="sName">Procedural</span>
                                    <br>
                                    <span style="color:#204563;white-space: pre-line;" class="sName">Auscultation pulmonaire</span><br>
                                    <a href="#speakers" @click="slideTo(13)" style="text-decoration:none">
                                        <span class="ml-2 orateurs">W. El Khattabi, Casablanca, Maroc</span>
                                    </a>
                                    <hr class="hr3">
                                </div>
                                <div>
                                    <span style="color:#E4A837"> Track 4 </span><span style="color:#E4A837" class="sName">Procedural</span>
                                    <br>
                                    <span style="color:#204563;white-space: pre-line;" class="sName">Réanimation néonatale</span><br>
                                    <a href="#speakers" @click="slideTo(13)" style="text-decoration:none">
                                        <span class="ml-2 orateurs">N. Dini, Casablanca, Maroc |</span>
                                    </a>
                                    <a href="#speakers" @click="slideTo(13)" style="text-decoration:none">
                                        <span class="ml-2 orateurs"> I. Ammari, Casablanca, Maroc</span>
                                    </a>
                                    <hr class="hr3">
                                </div>
                                <div>
                                    <span style="color:#E4A837"> Track 5 </span><span style="color:#E4A837" class="sName">Interprofessionnel</span>
                                    <br>
                                    <span style="color:#204563;white-space: pre-line;" class="sName">Curriculum Soft skills ou comment améliorer ses compétences non techniques Part II</span><br>
                                    <a href="#speakers" @click="slideTo(13)" style="text-decoration:none">
                                        <span class="ml-2 orateurs">M. Fourtassi, Tanger, Maroc |</span>
                                    </a>
                                    <a href="#speakers" @click="slideTo(13)" style="text-decoration:none">
                                        <span class="ml-2 orateurs"> M. El Mouhajir, Rabat, Maroc</span>
                                    </a>
                                    <hr class="hr3">
                                </div>
                                <div>
                                    <span style="color:#E4A837"> Track 6 </span><span style="color:#E4A837" class="sName">Haptic</span>
                                    <br>
                                    <span style="color:#204563;white-space: pre-line;" class="sName">Curriculum Coelioscopie en chirurgie viscérale Part II</span><br>
                                    <a href="#speakers" @click="slideTo(13)" style="text-decoration:none">
                                        <span class="ml-2 orateurs">H. Fennane, Marrakech, Maroc |</span>
                                    </a>
                                    <a href="#speakers" @click="slideTo(13)" style="text-decoration:none">
                                        <span class="ml-2 orateurs"> M. Soufi, Agadir, Maroc</span>
                                    </a><br>
                                    <span style="color:#204563;white-space: pre-line;" class="sName">Curriculum Endoscopie digestive Part II</span><br>
                                    <a href="#speakers" @click="slideTo(13)" style="text-decoration:none">
                                        <span class="ml-2 orateurs">N. Aqodad, Agadir, Maroc |</span>
                                    </a>
                                    <a href="#speakers" @click="slideTo(13)" style="text-decoration:none">
                                        <span class="ml-2 orateurs"> E. Maidi, Agadir, Maroc</span>
                                    </a>
                                    <hr class="hr3">
                                </div>
                                <div>
                                    <span style="color:#E4A837"> Track 7 </span><span style="color:#E4A837" class="sName">Ultrasound</span>
                                    <br>
                                    <span style="color:#204563;white-space: pre-line;" class="sName">Curriculum Échographie hépato-biliaire Part II</span><br>
                                    <a href="#speakers" @click="slideTo(13)" style="text-decoration:none">
                                        <span class="ml-2 orateurs">N. Touil, Casablanca, Maroc |</span>
                                    </a>
                                    <a href="#speakers" @click="slideTo(13)" style="text-decoration:none">
                                        <span class="ml-2 orateurs"> H. Tabakh, Casablanca, Maroc</span>
                                    </a>
                                    <hr class="hr3">
                                </div>
                                <div>
                                    <span style="color:#E4A837"> Track 8 </span><span style="color:#E4A837" class="sName">Sim Galaxy 360°</span>
                                    <br>
                                    <span style="color:#204563;white-space: pre-line;" class="sName">Scenarii Urgences médicales Part II</span><br>
                                    <a href="#speakers" @click="slideTo(13)" style="text-decoration:none">
                                        <span class="ml-2 orateurs">O. Rabbai, Agadir, Maroc |</span>
                                    </a>
                                    <a href="#speakers" @click="slideTo(13)" style="text-decoration:none">
                                        <span class="ml-2 orateurs"> B. Armel, Salé, Maroc |</span>
                                    </a>
                                    <a href="#speakers" @click="slideTo(13)" style="text-decoration:none">
                                        <span class="ml-2 orateurs"> L. Yara, Laayoune, Maroc</span>
                                    </a>
                                    <hr class="hr3">
                                </div>
                            </v-col>
                        </v-row>
                    </v-timeline-item>

                    <v-timeline-item color="blue" small>
                        <v-row class="pt-1 timelineprog">
                            <v-col cols="8">
                                <div class="heurire p-1">14H30 - 16H00</div>
                            </v-col>
                            <v-col cols="12">    
                               <div>
                                    <span style="color:#E4A837"> Track 1 </span><span style="color:#E4A837" class="sName">Masterclass</span>
                                    <br>
                                    <span style="color:#204563;white-space: pre-line;" class="sName">Pédagogie et enseignement par simulation en santé Part I</span><br>
                                    <a href="#speakers" @click="slideTo(13)" style="text-decoration:none">
                                        <span class="ml-2 orateurs">B. Boukatta, Fès, Maroc |</span>
                                    </a>
                                    <a href="#speakers" @click="slideTo(13)" style="text-decoration:none">
                                        <span class="ml-2 orateurs"> H. Nassik, Agadir, Maroc |</span>
                                    </a>
                                    <a href="#speakers" @click="slideTo(13)" style="text-decoration:none">
                                        <span class="ml-2 orateurs"> A. Bentalha, Rabat, Maroc |</span>
                                    </a>
                                    <a href="#speakers" @click="slideTo(13)" style="text-decoration:none">
                                        <span class="ml-2 orateurs"> H. Sbai, Tanger, Maroc|</span>
                                    </a>
                                    <hr class="hr3">
                                </div>
                                <div>
                                    <span style="color:#E4A837"> Track 2 </span><span style="color:#E4A837" class="sName">Hackathon & télé-simulation</span>
                                    <br>
                                    <span style="color:#204563;white-space: pre-line;" class="sName">Foire aux scenarii Part I</span><br>
                                     <a href="#speakers" @click="slideTo(13)" style="text-decoration:none">
                                        <span class="ml-2 orateurs">M. Mouhaoui, Casablanca, Maroc |</span>
                                    </a>
                                    <a href="#speakers" @click="slideTo(13)" style="text-decoration:none">
                                        <span class="ml-2 orateurs"> B. Aggoug, Casablanca, Maroc |</span>
                                    </a>
                                    <a href="#speakers" @click="slideTo(13)" style="text-decoration:none">
                                        <span class="ml-2 orateurs"> A. El Mekkaoui, Agadir, Maroc</span>
                                    </a>
                                    <hr class="hr3">
                                </div>
                                <div>
                                    <span style="color:#E4A837"> Track 3 </span><span style="color:#E4A837" class="sName">Standardized patient</span>
                                    <br>
                                    <span style="color:#204563;white-space: pre-line;" class="sName">Examen neurologique</span><br>
                                    <a href="#speakers" @click="slideTo(13)" style="text-decoration:none">
                                        <span class="ml-2 orateurs">J. Asfara, Casablanca, Maroc</span>
                                    </a>
                                    <hr class="hr3">
                                </div>
                                <div>
                                    <span style="color:#E4A837"> Track 4 </span><span style="color:#E4A837" class="sName">Procedural</span>
                                    <br>
                                    <span style="color:#204563;white-space: pre-line;" class="sName">Cathétérisme veineux</span><br>
                                    <a href="#speakers" @click="slideTo(13)" style="text-decoration:none">
                                        <span class="ml-2 orateurs">Y. Motaai, Tanger, Maroc |</span>
                                    </a>
                                    <a href="#speakers" @click="slideTo(13)" style="text-decoration:none">
                                        <span class="ml-2 orateurs"> M. Aabdi, Tanger, Maroc</span>
                                    </a>
                                    <hr class="hr3">
                                </div>
                                <div>
                                    <span style="color:#E4A837"> Track 5 </span><span style="color:#E4A837" class="sName">Interprofessionnel</span>
                                    <br>
                                    <span style="color:#204563;white-space: pre-line;" class="sName">Curriculum Sages-femmes Part I</span><br>
                                    <span style="color:#204563;white-space: pre-line;" class="sName">Workshop Épisiotomie</span><br>
                                    <a href="#speakers" @click="slideTo(13)" style="text-decoration:none">
                                        <span class="ml-2 orateurs">R. Matine, Tanger, Maroc</span>
                                    </a>
                                    <hr class="hr3">
                                </div>
                                <div>
                                    <span style="color:#E4A837"> Track 6 </span><span style="color:#E4A837" class="sName">Haptic</span>
                                    <br>
                                    <span style="color:#204563;white-space: pre-line;" class="sName">Curriculum Thoracoscopie Part I</span><br>
                                    <a href="#speakers" @click="slideTo(13)" style="text-decoration:none">
                                        <span class="ml-2 orateurs">I. Alloubi, Tanger, Maroc |</span>
                                    </a>
                                    <a href="#speakers" @click="slideTo(13)" style="text-decoration:none">
                                        <span class="ml-2 orateurs"> I. Mellouki, Tanger, Maroc</span>
                                    </a><br>
                                    <span style="color:#204563;white-space: pre-line;" class="sName">Curriculum Bronchoscopie Part I</span><br>
                                    <a href="#speakers" @click="slideTo(13)" style="text-decoration:none">
                                        <span class="ml-2 orateurs">W. El Khattabi, Casablanca, Maroc |</span>
                                    </a>
                                    <a href="#speakers" @click="slideTo(13)" style="text-decoration:none">
                                        <span class="ml-2 orateurs"> H. Serhane, Agadir, Maroc</span>
                                    </a>
                                    <hr class="hr3">
                                </div>
                                <div>
                                    <span style="color:#E4A837"> Track 7 </span><span style="color:#E4A837" class="sName">Ultrasound</span>
                                    <br>
                                    <span style="color:#204563;white-space: pre-line;" class="sName">Curriculum Échographie pelvienne Part I</span><br>
                                    <a href="#speakers" @click="slideTo(13)" style="text-decoration:none">
                                        <span class="ml-2 orateurs">N. Touil, Casablanca, Maroc |</span>
                                    </a>
                                    <a href="#speakers" @click="slideTo(13)" style="text-decoration:none">
                                        <span class="ml-2 orateurs"> H. Tabakh, Casablanca, Maroc</span>
                                    </a>
                                    <hr class="hr3">
                                </div>
                                <div>
                                    <span style="color:#E4A837"> Track 8 </span><span style="color:#E4A837" class="sName">Sim Galaxy 360°</span>
                                    <br>
                                    <span style="color:#204563;white-space: pre-line;" class="sName">Scenarii Urgences chirurgicales Part I</span><br>
                                    <a href="#speakers" @click="slideTo(13)" style="text-decoration:none">
                                        <span class="ml-2 orateurs">N. Damghi, Kénitra, Maroc |</span>
                                    </a>
                                    <a href="#speakers" @click="slideTo(13)" style="text-decoration:none">
                                        <span class="ml-2 orateurs"> M. Bouchbika, Salé, Maroc |</span>
                                    </a>
                                    <a href="#speakers" @click="slideTo(13)" style="text-decoration:none">
                                        <span class="ml-2 orateurs"> J. Belghali, Casablanca, Maroc</span>
                                    </a>
                                    <hr class="hr3">
                                </div>
                            </v-col>
                        </v-row>
                    </v-timeline-item>

                    <v-timeline-item color="blue" small>
                        <v-row class="pt-1 timelineprog">
                            <v-col cols="8">
                                <div class="heurire p-1">16H30 - 18H00</div>
                            </v-col>
                            <v-col cols="12">    
                                <div>
                                    <span style="color:#E4A837"> Track 1 </span><span style="color:#E4A837" class="sName">Masterclass</span>
                                    <br>
                                    <span style="color:#204563;white-space: pre-line;" class="sName">Pédagogie et enseignement par simulation en santé Part II</span><br>
                                    <a href="#speakers" @click="slideTo(13)" style="text-decoration:none">
                                        <span class="ml-2 orateurs">B. Boukatta, Fès, Maroc |</span>
                                    </a>
                                    <a href="#speakers" @click="slideTo(13)" style="text-decoration:none">
                                        <span class="ml-2 orateurs"> H. Nassik, Agadir, Maroc |</span>
                                    </a>
                                    <a href="#speakers" @click="slideTo(13)" style="text-decoration:none">
                                        <span class="ml-2 orateurs"> A. Bentalha, Rabat, Maroc |</span>
                                    </a>
                                    <a href="#speakers" @click="slideTo(13)" style="text-decoration:none">
                                        <span class="ml-2 orateurs"> H. Sbai, Tanger, Maroc</span>
                                    </a>
                                    <hr class="hr3">
                                </div>
                                <div>
                                    <span style="color:#E4A837"> Track 2 </span><span style="color:#E4A837" class="sName">Hackathon & télé-simulation</span>
                                    <br>
                                    <span style="color:#204563;white-space: pre-line;" class="sName">Foire aux scenarii Part II</span><br>
                                    <a href="#speakers" @click="slideTo(13)" style="text-decoration:none">
                                        <span class="ml-2 orateurs">M. Mouhaoui, Casablanca, Maroc |</span>
                                    </a>
                                    <a href="#speakers" @click="slideTo(13)" style="text-decoration:none">
                                        <span class="ml-2 orateurs"> B. Aggoug, Casablanca, Maroc |</span>
                                    </a>
                                    <a href="#speakers" @click="slideTo(13)" style="text-decoration:none">
                                        <span class="ml-2 orateurs"> A. El Mekkaoui, Agadir, Maroc</span>
                                    </a>
                                    <hr class="hr3">
                                </div>
                                <div>
                                    <span style="color:#E4A837"> Track 3 </span><span style="color:#E4A837" class="sName">Hybrid</span>
                                    <br>
                                    <span style="color:#204563;white-space: pre-line;" class="sName">Examen abdominal</span><br>
                                    <a href="#speakers" @click="slideTo(13)" style="text-decoration:none">
                                        <span class="ml-2 orateurs">F. Thys, Charleroi, Belgique</span>
                                    </a>
                                    <hr class="hr3">
                                </div>
                                <div>
                                    <span style="color:#E4A837"> Track 4 </span><span style="color:#E4A837" class="sName">Procedural</span>
                                    <br>
                                    <span style="color:#204563;white-space: pre-line;" class="sName">Ponctions</span><br>
                                    <a href="#speakers" @click="slideTo(13)" style="text-decoration:none">
                                        <span class="ml-2 orateurs">M. Bourquia, Tanger, Maroc |</span>
                                    </a>
                                    <a href="#speakers" @click="slideTo(13)" style="text-decoration:none">
                                        <span class="ml-2 orateurs"> R. Belfkih, Tanger, Maroc |</span>
                                    </a>
                                    <a href="#speakers" @click="slideTo(13)" style="text-decoration:none">
                                        <span class="ml-2 orateurs"> M. Assem, Tanger, Maroc</span>
                                    </a>
                                    <hr class="hr3">
                                </div>
                                <div>
                                    <span style="color:#E4A837"> Track 5 </span><span style="color:#E4A837" class="sName">Interprofessionnel</span>
                                    <br>
                                    <span style="color:#204563;white-space: pre-line;" class="sName">Curriculum Sages-femmes Part II</span><br>
                                    <span style="color:#204563;white-space: pre-line;" class="sName">Workshop Épisiotomie</span><br>
                                    <a href="#speakers" @click="slideTo(13)" style="text-decoration:none">
                                        <span class="ml-2 orateurs">R. Matine, Tanger, Maroc</span>
                                    </a>
                                    <hr class="hr3">
                                </div>
                                <div>
                                    <span style="color:#E4A837"> Track 6 </span><span style="color:#E4A837" class="sName">Haptic</span>
                                    <br>
                                    <span style="color:#204563;white-space: pre-line;" class="sName">Curriculum Thoracoscopie Part II</span><br>
                                    <a href="#speakers" @click="slideTo(13)" style="text-decoration:none">
                                        <span class="ml-2 orateurs">I. Alloubi, Tanger, Maroc |</span>
                                    </a>
                                    <a href="#speakers" @click="slideTo(13)" style="text-decoration:none">
                                        <span class="ml-2 orateurs"> I. Mellouk, Tanger, Maroc</span>
                                    </a><br>
                                    <span style="color:#204563;white-space: pre-line;" class="sName">Curriculum Bronchoscopie Part II</span><br>
                                    <a href="#speakers" @click="slideTo(13)" style="text-decoration:none">
                                        <span class="ml-2 orateurs">W. El Khattabi, Casablanca, Maroc |</span>
                                    </a>
                                    <a href="#speakers" @click="slideTo(13)" style="text-decoration:none">
                                        <span class="ml-2 orateurs"> H. Serhane, Agadir, Maroc</span>
                                    </a>
                                    <hr class="hr3">
                                </div>
                                <div>
                                    <span style="color:#E4A837"> Track 7 </span><span style="color:#E4A837" class="sName">Ultrasound</span>
                                    <br>
                                    <span style="color:#204563;white-space: pre-line;" class="sName">Curriculum Échographie pelvienne Part II</span><br>
                                    <a href="#speakers" @click="slideTo(13)" style="text-decoration:none">
                                        <span class="ml-2 orateurs">N. Touil, Casablanca, Maroc |</span>
                                    </a>
                                    <a href="#speakers" @click="slideTo(13)" style="text-decoration:none">
                                        <span class="ml-2 orateurs"> H. Tabakh, Casablanca, Maroc</span>
                                    </a>
                                    <hr class="hr3">
                                </div>
                                <div>
                                    <span style="color:#E4A837"> Track 8 </span><span style="color:#E4A837" class="sName">Sim Galaxy 360°</span>
                                    <br>
                                    <span style="color:#204563;white-space: pre-line;" class="sName">Scenarii Urgences chirurgicales Part II</span><br>
                                    <a href="#speakers" @click="slideTo(13)" style="text-decoration:none">
                                        <span class="ml-2 orateurs">N. Damghi, Kénitra, Maroc |</span>
                                    </a>
                                    <a href="#speakers" @click="slideTo(13)" style="text-decoration:none">
                                        <span class="ml-2 orateurs"> M. Bouchbika, Salé, Maroc |</span>
                                    </a>
                                    <a href="#speakers" @click="slideTo(13)" style="text-decoration:none">
                                        <span class="ml-2 orateurs"> J. Belghali, Casablanca, Maroc</span>
                                    </a>
                                    <hr class="hr3">
                                </div>
                            </v-col>
                        </v-row>
                    </v-timeline-item>
                </v-timeline>
            </div>
        </v-card>
        </v-container>
    </v-app>
</template>

<script>
  export default {
    methods: {
        slideTo(num){
            this.$emit('gotoslide',num)
        }
    },
  }
</script>

<style>
.heurire{
    max-width: 120px;
    color: white;
    background-color: #E4A837;
    font-size: 15px;
}

.agendaback{
    background-color: #0F5288!important;
}

.orateurs{
    color: #333533;
}

.orateurs:hover{
    color: #204563;
}

.date{
    font-family: 'Montserrat', sans-serif;
    color: #165274;
    background-color: #E4A837;
    font-size: 19px;
}
hr {
  margin: 10px 0;
}

.hr3 {
  border: 0;
  height: 2px;
  background-image: linear-gradient(to right, transparent, #CCC, transparent);  
  
}

.sName{
    font-family: 'Montserrat', sans-serif;
}

.seanceTitle{
    font-family: 'Montserrat', sans-serif;
}

@media (max-width: 750px) {

 .timelineprog{
    margin-left: -32px!important;
  }

}
 

@media (max-width: 380px) {
  .v-label{
      font-size: 15px!important;
  }

  .sName{
        font-size: 13px!important;
    }

.orateurs{
    font-size: 11px!important;
}

}

@media (max-width: 380px) {
  .timeline{
      margin-left: -20px!important;
  }
}
</style>
