<template >
    <v-app class="agendaback">
        <v-container>
        <v-card>
            <div>
                <div class="p-2">
                    <v-img  src="logo.png"  max-width="300" ></v-img>
                </div>
                <div class="date p-2">
                    <strong>VENDREDI 25 FÉVRIER 2022</strong>
                </div>
                <v-timeline align-top dense class="timeline">
                    <v-timeline-item color="blue" small>
                        <v-row class="pt-1 timelineprog">
                            <v-col cols="8">
                                <div class="heurire p-1">09H30 - 11H00</div>
                            </v-col>
                            <v-col cols="12">    
                                <v-row>
                                    <v-col cols="12">
                                        <div>
                                            <span style="color:#E4A837"> Track 1 </span><span style="color:#E4A837" class="sName">Conférences Updates II</span>
                                            <br>
                                            <span style="color:#204563;white-space: pre-line;" class="sName">Bridging medical simulation with science and engineering</span><br>
                                            <a href="#speakers" @click="slideTo(13)" style="text-decoration:none">
                                                <span class="ml-2 orateurs">R. Warrak, Casablanca, Maroc</span>
                                            </a><br>
                                            <span style="color:#204563;white-space: pre-line;" class="sName">Innovation et renouveau de la pédagogie universitaire : retour d’expérience</span><br>
                                            <a href="#speakers" @click="slideTo(13)" style="text-decoration:none">
                                                <span class="ml-2 orateurs">J. Benhammou, Casablanca, Maroc |</span>
                                            </a><br>
                                            <span style="color:#204563;white-space: pre-line;" class="sName">Éthique et innovations technologiques</span><br>
                                            <a href="#speakers" @click="slideTo(13)" style="text-decoration:none">
                                                <span class="ml-2 orateurs"> F. Thys, Charleroi, Belgique</span>
                                            </a><br>
                                            <hr class="hr3">
                                        </div>
                                        <div>
                                            <span style="color:#E4A837"> Track 2 </span><span style="color:#E4A837" class="sName">Réalité virtuelle & réalité augmentée en chirurgie</span>
                                            <br>
                                            <span style="color:#204563;white-space: pre-line;" class="sName">Réalité augmentée & opportunités éducatives</span><br>
                                            <a href="#speakers" @click="slideTo(13)" style="text-decoration:none">
                                                <span class="ml-2 orateurs">C. Chidiac, Beyrouth, Liban</span>
                                            </a><br>
                                            <span style="color:#204563;white-space: pre-line;" class="sName">Formation en Cardiologie, place du "Blended Learning" sur Echo, CEC, ECMO, Simulation HIFI et Serious Games</span><br>
                                            <a href="#speakers" @click="slideTo(13)" style="text-decoration:none">
                                                <span class="ml-2 orateurs">A. Mignon, Paris,France</span>
                                            </a>
                                            <hr class="hr3">
                                        </div>
                                        <div>
                                            <span style="color:#E4A837"> Track 3 </span><span style="color:#E4A837" class="sName">Procedural</span>
                                            <br>
                                            <span style="color:#204563;white-space: pre-line;" class="sName">Examen gynécologique</span><br>
                                            <a href="#speakers" @click="slideTo(13)" style="text-decoration:none">
                                                <span class="ml-2 orateurs">Y. Ait Benkaddour, Marrakech, Maroc |</span>
                                            </a>
                                            <a href="#speakers" @click="slideTo(13)" style="text-decoration:none">
                                                <span class="ml-2 orateurs"> B. Fakhir, Marrakech, Maroc</span>
                                            </a>
                                            <hr class="hr3">
                                        </div>
                                        <div>
                                            <span style="color:#E4A837"> Track 4 </span><span style="color:#E4A837" class="sName">Trainer</span>
                                            <br>
                                            <span style="color:#204563;white-space: pre-line;" class="sName">ECG Trainer Part I</span><br>
                                            <a href="#speakers" @click="slideTo(13)" style="text-decoration:none">
                                                <span class="ml-2 orateurs">H. El Hamzaoui, Rabat, Maroc</span>
                                            </a>
                                            <hr class="hr3">
                                        </div>
                                        <div>
                                            <span style="color:#E4A837"> Track 5 </span><span style="color:#E4A837" class="sName">Interprofessionnel</span>
                                            <br>
                                            <span style="color:#204563;white-space: pre-line;" class="sName">Curriculum Rééducation & Réhabilitation post-AVC Part I</span><br>
                                            <a href="#speakers" @click="slideTo(13)" style="text-decoration:none">
                                                <span class="ml-2 orateurs">R. Zaoujal, Tanger, Maroc |</span>
                                            </a>
                                            <a href="#speakers" @click="slideTo(13)" style="text-decoration:none">
                                                <span class="ml-2 orateurs"> H. Bouayad, Rabat, Maroc</span>
                                            </a>
                                            <hr class="hr3">
                                        </div>
                                        <div>
                                            <span style="color:#E4A837"> Track 6 </span><span style="color:#E4A837" class="sName">Haptic</span>
                                            <br>
                                            <span style="color:#204563;white-space: pre-line;" class="sName">Curriculum Urologie endoscopique Part I</span><br>
                                            <a href="#speakers" @click="slideTo(13)" style="text-decoration:none">
                                                <span class="ml-2 orateurs">Z. Dahami, Marrakech, Maroc |</span>
                                            </a>
                                            <a href="#speakers" @click="slideTo(13)" style="text-decoration:none">
                                                <span class="ml-2 orateurs"> . Ziouziou, Agadir, Maroc</span>
                                            </a>
                                            <hr class="hr3">
                                        </div>
                                        <div>
                                            <span style="color:#E4A837"> Track 7 </span><span style="color:#E4A837" class="sName">Ultrasound</span>
                                            <br>
                                            <span style="color:#204563;white-space: pre-line;" class="sName">Curriculum Fast Echo Part I</span><br>
                                            <a href="#speakers" @click="slideTo(13)" style="text-decoration:none">
                                                <span class="ml-2 orateurs">A. Kettani, Rabat, Maroc |</span>
                                            </a>
                                            <a href="#speakers" @click="slideTo(13)" style="text-decoration:none">
                                                <span class="ml-2 orateurs"> Z. Belkhadir, Rabat, Maroc</span>
                                            </a>
                                            <hr class="hr3">
                                        </div>
                                        <div>
                                            <span style="color:#E4A837"> Track 8 </span><span style="color:#E4A837" class="sName">Sim Galaxy 360°</span>
                                            <br>
                                            <span style="color:#204563;white-space: pre-line;" class="sName">Scenarii Traumatologie vitale Part I</span><br>
                                            <a href="#speakers" @click="slideTo(13)" style="text-decoration:none">
                                                <span class="ml-2 orateurs">FZ. Beniaz, Casablanca, Maroc |</span>
                                            </a>
                                            <a href="#speakers" @click="slideTo(13)" style="text-decoration:none">
                                                <span class="ml-2 orateurs"> MA. Ait Achour, Casablanca, Maroc |</span>
                                            </a>
                                            <a href="#speakers" @click="slideTo(13)" style="text-decoration:none">
                                                <span class="ml-2 orateurs"> I. Abousahfa, Casablanca, Maroc</span>
                                            </a>
                                            <hr class="hr3">
                                        </div>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                    </v-timeline-item>

                    <v-timeline-item color="blue" small>
                        <v-row class="pt-1 timelineprog">
                            <v-col cols="8">
                                <div class="heurire p-1">11H30 - 13H00</div>
                            </v-col>
                            <v-col cols="12">    
                                <div>
                                    <span style="color:#E4A837"> Track 1 </span><span style="color:#E4A837" class="sName">Symposia</span>
                                    <br>
                                    <span style="color:#204563;white-space: pre-line;" class="sName">ECOS, la technique au service de la pédagogie</span><br>
                                    <a href="#speakers" @click="slideTo(13)" style="text-decoration:none">
                                        <span class="ml-2 orateurs">F. Beger, France</span>
                                    </a><br>
                                    <span style="color:#204563;white-space: pre-line;" class="sName">Innovations technologiques pour le diagnostic rapide : exemple de la biologie moléculaire</span><br>
                                    <a href="#speakers" @click="slideTo(13)" style="text-decoration:none">
                                        <span class="ml-2 orateurs">T. Dendane, Maroc</span>
                                    </a>
                                    <hr class="hr3">
                                </div>
                                <div>
                                    <span style="color:#E4A837"> Track 2 </span><span style="color:#E4A837" class="sName">Réalité virtuelle & réalité augmentée</span>
                                    <br>
                                    <span style="color:#204563;white-space: pre-line;" class="sName">Réalité virtuelle en chirurgie</span><br>
                                    <a href="#speakers" @click="slideTo(13)" style="text-decoration:none">
                                        <span class="ml-2 orateurs">A. Rafaoui, Casablanca, Maroc</span>
                                    </a>
                                    <hr class="hr3">
                                </div>
                                <div>
                                    <span style="color:#E4A837"> Track 3 </span><span style="color:#E4A837" class="sName">Procedural</span>
                                    <br>
                                    <span style="color:#204563;white-space: pre-line;" class="sName">Examen ORL</span><br>
                                    <a href="#speakers" @click="slideTo(13)" style="text-decoration:none">
                                        <span class="ml-2 orateurs">S. Anajjar, Casablanca, Maroc |</span>
                                    </a>
                                    <a href="#speakers" @click="slideTo(13)" style="text-decoration:none">
                                        <span class="ml-2 orateurs"> I. Rkain, Tanger, Maroc</span>
                                    </a>
                                    <hr class="hr3">
                                </div>
                                <div>
                                    <span style="color:#E4A837"> Track 4 </span><span style="color:#E4A837" class="sName">Trainer</span>
                                    <br>
                                    <span style="color:#204563;white-space: pre-line;" class="sName">ECG Trainer Part II</span><br>
                                    <a href="#speakers" @click="slideTo(13)" style="text-decoration:none">
                                        <span class="ml-2 orateurs">H. El Hamzaoui, Rabat, Maroc</span>
                                    </a>
                                    <hr class="hr3">
                                </div>
                                <div>
                                    <span style="color:#E4A837"> Track 5 </span><span style="color:#E4A837" class="sName">Interprofessionnel</span>
                                    <br>
                                    <span style="color:#204563;white-space: pre-line;" class="sName">Curriculum Rééducation & Réhabilitation post-AVC Part II</span><br>
                                    <a href="#speakers" @click="slideTo(13)" style="text-decoration:none">
                                        <span class="ml-2 orateurs">R. Zaoujal, Tanger, Maroc |</span>
                                    </a>
                                    <a href="#speakers" @click="slideTo(13)" style="text-decoration:none">
                                        <span class="ml-2 orateurs"> H. Bouayad, Rabat, Maroc</span>
                                    </a>
                                    <hr class="hr3">
                                </div>
                                <div>
                                    <span style="color:#E4A837"> Track 6 </span><span style="color:#E4A837" class="sName">Haptic</span>
                                    <br>
                                    <span style="color:#204563;white-space: pre-line;" class="sName">Curriculum Urologie endoscopique Part II</span><br>
                                    <a href="#speakers" @click="slideTo(13)" style="text-decoration:none">
                                        <span class="ml-2 orateurs">Z. Dahami, Marrakech, Maroc |</span>
                                    </a>
                                    <a href="#speakers" @click="slideTo(13)" style="text-decoration:none">
                                        <span class="ml-2 orateurs"> I. Ziouziou, Agadir, Maroc</span>
                                    </a>
                                    <hr class="hr3">
                                </div>
                                <div>
                                    <span style="color:#E4A837"> Track 7 </span><span style="color:#E4A837" class="sName">Ultrasound</span>
                                    <br>
                                    <span style="color:#204563;white-space: pre-line;" class="sName">Curriculum Fast Echo Part I</span><br>
                                    <a href="#speakers" @click="slideTo(13)" style="text-decoration:none">
                                        <span class="ml-2 orateurs">A. Kettani, Rabat, Maroc |</span>
                                    </a>
                                    <a href="#speakers" @click="slideTo(13)" style="text-decoration:none">
                                        <span class="ml-2 orateurs"> Z. Belkhadir, Rabat, Maroc</span>
                                    </a>
                                    <hr class="hr3">
                                </div>
                                <div>
                                    <span style="color:#E4A837"> Track 8 </span><span style="color:#E4A837" class="sName">Sim Galaxy 360°</span>
                                    <br>
                                    <span style="color:#204563;white-space: pre-line;" class="sName">Scenarii Traumatologie vitale Part II</span><br>
                                    <a href="#speakers" @click="slideTo(13)" style="text-decoration:none">
                                        <span class="ml-2 orateurs">FZ. Beniaz, Casablanca, Maroc |</span>
                                    </a>
                                    <a href="#speakers" @click="slideTo(13)" style="text-decoration:none">
                                        <span class="ml-2 orateurs"> MA. Ait Achour, Casablanca, Maroc |</span>
                                    </a>
                                    <a href="#speakers" @click="slideTo(13)" style="text-decoration:none">
                                        <span class="ml-2 orateurs"> I. Abousahfa, Casablanca, Maroc</span>
                                    </a>
                                    <hr class="hr3">
                                </div>
                            </v-col>
                        </v-row>
                    </v-timeline-item>

                    <v-timeline-item color="blue" small>
                        <v-row class="pt-1 timelineprog">
                            <v-col cols="8">
                                <div class="heurire p-1">14H30 - 16H00</div>
                            </v-col>
                            <v-col cols="12">    
                               <div>
                                    <span style="color:#E4A837"> Track 1 </span><span style="color:#E4A837" class="sName">Masterclass</span>
                                    <br>
                                    <span style="color:#204563;white-space: pre-line;" class="sName">Pédagogie et enseignement par simulation en santé Part III</span><br>
                                    <a href="#speakers" @click="slideTo(13)" style="text-decoration:none">
                                        <span class="ml-2 orateurs">B. Boukatta, Fès, Maroc |</span>
                                    </a>
                                    <a href="#speakers" @click="slideTo(13)" style="text-decoration:none">
                                        <span class="ml-2 orateurs"> H. Nassik, Agadir, Maroc |</span>
                                    </a>
                                    <a href="#speakers" @click="slideTo(13)" style="text-decoration:none">
                                        <span class="ml-2 orateurs"> A. Bentalha, Rabat, Maroc |</span>
                                    </a>
                                    <a href="#speakers" @click="slideTo(13)" style="text-decoration:none">
                                        <span class="ml-2 orateurs"> H. Sbai, Tanger, Maroc|</span>
                                    </a>
                                    <hr class="hr3">
                                </div>
                                <div>
                                    <span style="color:#E4A837"> Track 2 </span><span style="color:#E4A837" class="sName">Casuality simulation</span>
                                    <br>
                                    <span style="color:#204563;white-space: pre-line;" class="sName">Casuality simulaiton challenge Part I</span><br>
                                     <a href="#speakers" @click="slideTo(13)" style="text-decoration:none">
                                        <span class="ml-2 orateurs">J. Al Faroukh, Dubai, Émirates Arabes Unies |</span>
                                    </a>
                                    <a href="#speakers" @click="slideTo(13)" style="text-decoration:none">
                                        <span class="ml-2 orateurs"> Y. Zidoun, Dubai, Émirates Arabes Unies</span>
                                    </a>
                                    <hr class="hr3">
                                </div>
                                <div>
                                    <span style="color:#E4A837"> Track 3 </span><span style="color:#E4A837" class="sName">Procedural</span>
                                    <br>
                                    <span style="color:#204563;white-space: pre-line;" class="sName">Examen ophtalmologique</span><br>
                                    <a href="#speakers" @click="slideTo(13)" style="text-decoration:none">
                                        <span class="ml-2 orateurs">A. Mchachi, Casablanca, Maroc |</span>
                                    </a>
                                    <a href="#speakers" @click="slideTo(13)" style="text-decoration:none">
                                        <span class="ml-2 orateurs"> M. El Bahloul, Tanger, Maroc</span>
                                    </a>
                                    <hr class="hr3">
                                </div>
                                <div>
                                    <span style="color:#E4A837"> Track 4 </span><span style="color:#E4A837" class="sName">Hybrid</span>
                                    <br>
                                    <span style="color:#204563;white-space: pre-line;" class="sName">Curriculum Outils pratiques en néphrologie Part I</span><br>
                                    <a href="#speakers" @click="slideTo(13)" style="text-decoration:none">
                                        <span class="ml-2 orateurs">T. Sqalli, Fès, Maroc</span>
                                    </a>
                                    <hr class="hr3">
                                </div>
                                <div>
                                    <span style="color:#E4A837"> Track 5 </span><span style="color:#E4A837" class="sName">Open</span>
                                    <br>
                                    <span style="color:#204563;white-space: pre-line;" class="sName">Curriculum Nutrition dans la peau d'un obèse Part I</span><br>
                                    <a href="#speakers" @click="slideTo(13)" style="text-decoration:none">
                                        <span class="ml-2 orateurs">N. El Ansari, Marrakech, Maroc |</span>
                                    </a>
                                    <a href="#speakers" @click="slideTo(13)" style="text-decoration:none">
                                        <span class="ml-2 orateurs"> G. El Mghari Tabib, Marrakech, Maroc</span>
                                    </a>
                                    <hr class="hr3">
                                </div>
                                <div>
                                    <span style="color:#E4A837"> Track 6 </span><span style="color:#E4A837" class="sName">Haptic</span>
                                    <br>
                                    <span style="color:#204563;white-space: pre-line;" class="sName">Curriculum Coelioscopie en gynécologie Part I</span><br>
                                    <a href="#speakers" @click="slideTo(13)" style="text-decoration:none">
                                        <span class="ml-2 orateurs">Y. Ait Benkaddour, Marrakech, Maroc |</span>
                                    </a>
                                    <a href="#speakers" @click="slideTo(13)" style="text-decoration:none">
                                        <span class="ml-2 orateurs"> B. Fakhir, Marrakech, Maroc</span>
                                    </a>
                                    <hr class="hr3">
                                </div>
                                <div>
                                    <span style="color:#E4A837"> Track 7 </span><span style="color:#E4A837" class="sName">Ultrasound</span>
                                    <br>
                                    <span style="color:#204563;white-space: pre-line;" class="sName">Curriculum Échocoeur Part I</span><br>
                                    <a href="#speakers" @click="slideTo(13)" style="text-decoration:none">
                                        <span class="ml-2 orateurs">A. Kettani, Rabat, Maroc |</span>
                                    </a>
                                    <a href="#speakers" @click="slideTo(13)" style="text-decoration:none">
                                        <span class="ml-2 orateurs"> Z. Belkhadir, Rabat, Maroc</span>
                                    </a>
                                    <hr class="hr3">
                                </div>
                                <div>
                                    <span style="color:#E4A837"> Track 8 </span><span style="color:#E4A837" class="sName">Sim Galaxy 360°</span>
                                    <br>
                                    <span style="color:#204563;white-space: pre-line;" class="sName">Scenarii Urgences mère enfant Part I</span><br>
                                    <a href="#speakers" @click="slideTo(13)" style="text-decoration:none">
                                        <span class="ml-2 orateurs">Y. Benchehab, Casablanca, Maroc |</span>
                                    </a>
                                    <a href="#speakers" @click="slideTo(13)" style="text-decoration:none">
                                        <span class="ml-2 orateurs"> H. Jouhra, Guelmim, Maroc |</span>
                                    </a>
                                    <a href="#speakers" @click="slideTo(13)" style="text-decoration:none">
                                        <span class="ml-2 orateurs"> S. Baghdadi, Casablanca, Maroc</span>
                                    </a>
                                    <hr class="hr3">
                                </div>
                            </v-col>
                        </v-row>
                    </v-timeline-item>

                    <v-timeline-item color="blue" small>
                        <v-row class="pt-1 timelineprog">
                            <v-col cols="8">
                                <div class="heurire p-1">16H30 - 18H00</div>
                            </v-col>
                            <v-col cols="12">    
                                <div>
                                    <span style="color:#E4A837"> Track 1 </span><span style="color:#E4A837" class="sName">Masterclass</span>
                                    <br>
                                    <span style="color:#204563;white-space: pre-line;" class="sName">Pédagogie et enseignement par simulation en santé Part IV</span><br>
                                    <a href="#speakers" @click="slideTo(13)" style="text-decoration:none">
                                        <span class="ml-2 orateurs">B. Boukatta, Fès, Maroc |</span>
                                    </a>
                                    <a href="#speakers" @click="slideTo(13)" style="text-decoration:none">
                                        <span class="ml-2 orateurs"> H. Nassik, Agadir, Maroc |</span>
                                    </a>
                                    <a href="#speakers" @click="slideTo(13)" style="text-decoration:none">
                                        <span class="ml-2 orateurs"> A. Bentalha, Rabat, Maroc |</span>
                                    </a>
                                    <a href="#speakers" @click="slideTo(13)" style="text-decoration:none">
                                        <span class="ml-2 orateurs"> H. Sbai, Tanger, Maroc</span>
                                    </a>
                                    <hr class="hr3">
                                </div>
                                <div>
                                    <span style="color:#E4A837"> Track 2 </span><span style="color:#E4A837" class="sName">Hackathon & télé-simulation</span>
                                    <br>
                                    <span style="color:#204563;white-space: pre-line;" class="sName">Casuality simulaiton challenge Part II</span><br>
                                    <a href="#speakers" @click="slideTo(13)" style="text-decoration:none">
                                        <span class="ml-2 orateurs">J. Al Faroukh, Dubai, Émirates Arabes Unies |</span>
                                    </a>
                                    <a href="#speakers" @click="slideTo(13)" style="text-decoration:none">
                                        <span class="ml-2 orateurs"> Y. Zidoun, Dubai, Émirates Arabes Unies</span>
                                    </a>
                                    <hr class="hr3">
                                </div>
                                <div>
                                    <span style="color:#E4A837"> Track 3 </span><span style="color:#E4A837" class="sName">Hybrid</span>
                                    <br>
                                    <span style="color:#204563;white-space: pre-line;" class="sName">Drainage thoracique</span><br>
                                    <a href="#speakers" @click="slideTo(13)" style="text-decoration:none">
                                        <span class="ml-2 orateurs">S. Hammi, Tanger, Maroc |</span>
                                    </a>
                                    <a href="#speakers" @click="slideTo(13)" style="text-decoration:none">
                                        <span class="ml-2 orateurs"> K. Bouti, Tanger, Maroc</span>
                                    </a>
                                    <hr class="hr3">
                                </div>
                                <div>
                                    <span style="color:#E4A837"> Track 4 </span><span style="color:#E4A837" class="sName">Procedural</span>
                                    <br>
                                    <span style="color:#204563;white-space: pre-line;" class="sName">Curriculum Outils pratiques en néphrologie Part II</span><br>
                                    <a href="#speakers" @click="slideTo(13)" style="text-decoration:none">
                                        <span class="ml-2 orateurs">T. Sqalli, Fès, Maroc</span>
                                    </a>
                                    <hr class="hr3">
                                </div>
                                <div>
                                    <span style="color:#E4A837"> Track 5 </span><span style="color:#E4A837" class="sName">Interprofessionnel</span>
                                    <br>
                                    <span style="color:#204563;white-space: pre-line;" class="sName">Curriculum Nutrition dans la peau d'un obèse Part II</span><br>
                                    <a href="#speakers" @click="slideTo(13)" style="text-decoration:none">
                                        <span class="ml-2 orateurs">N. El Ansari, Marrakech, Maroc |</span>
                                    </a>
                                    <a href="#speakers" @click="slideTo(13)" style="text-decoration:none">
                                        <span class="ml-2 orateurs"> G. El Mghari Tabib, Marrakech, Maroc</span>
                                    </a>
                                    <hr class="hr3">
                                </div>
                                <div>
                                    <span style="color:#E4A837"> Track 6 </span><span style="color:#E4A837" class="sName">Haptic</span>
                                    <br>
                                    <span style="color:#204563;white-space: pre-line;" class="sName">Curriculum Coelioscopie en gynécologie Part II</span><br>
                                    <a href="#speakers" @click="slideTo(13)" style="text-decoration:none">
                                        <span class="ml-2 orateurs">Y. Ait Benkaddour, Marrakech, Maroc |</span>
                                    </a>
                                    <a href="#speakers" @click="slideTo(13)" style="text-decoration:none">
                                        <span class="ml-2 orateurs"> B. Fakhir, Marrakech, Maroc</span>
                                    </a>
                                    <hr class="hr3">
                                </div>
                                <div>
                                    <span style="color:#E4A837"> Track 7 </span><span style="color:#E4A837" class="sName">Ultrasound</span>
                                    <br>
                                    <span style="color:#204563;white-space: pre-line;" class="sName">Curriculum Échocoeur Part II</span><br>
                                    <a href="#speakers" @click="slideTo(13)" style="text-decoration:none">
                                        <span class="ml-2 orateurs">A. Kettani, Rabat, Maroc |</span>
                                    </a>
                                    <a href="#speakers" @click="slideTo(13)" style="text-decoration:none">
                                        <span class="ml-2 orateurs"> Z. Belkhadir, Rabat, Maroc</span>
                                    </a>
                                    <hr class="hr3">
                                </div>
                                <div>
                                    <span style="color:#E4A837"> Track 8 </span><span style="color:#E4A837" class="sName">Sim Galaxy 360°</span>
                                    <br>
                                    <span style="color:#204563;white-space: pre-line;" class="sName">Scenarii Urgences mère enfant Part II</span><br>
                                    <a href="#speakers" @click="slideTo(13)" style="text-decoration:none">
                                        <span class="ml-2 orateurs">Y. Benchehab, Casablanca, Maroc |</span>
                                    </a>
                                    <a href="#speakers" @click="slideTo(13)" style="text-decoration:none">
                                        <span class="ml-2 orateurs"> H. Jouhra, Guelmim, Maroc |</span>
                                    </a>
                                    <a href="#speakers" @click="slideTo(13)" style="text-decoration:none">
                                        <span class="ml-2 orateurs"> S. Baghdadi, Casablanca, Maroc</span>
                                    </a>
                                    <hr class="hr3">
                                </div>
                            </v-col>
                        </v-row>
                    </v-timeline-item>
                </v-timeline>
            </div>
        </v-card>
        </v-container>
    </v-app>
</template>

<script>
  export default {
    methods: {
        slideTo(num){
            this.$emit('gotoslide',num)
        }
    },
  }
</script>

<style>

</style>
