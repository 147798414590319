<template>
  <div class="wrapper">
    <main>
      <section class="demo">
        <div class="container">
          <div class="demo-title">
            <b>PRÉSENTATION<br />DE L'ÉVÉNEMENT</b>
          </div>
          <div class="demo-main">
            <p>
              L’association des médecins anesthésistes réanimateurs de Casablanca
              (AMARC) organise la 4ème édition de ses journées scientifiques les 10 et 11
              Mars 2023 à Casablanca.
              La 1ère journée aura lieu le 10 Mars 2023, à l’amphithéâtre Jamila Hachim,
              de la Faculté de Médecine et de Pharmacie de Casablanca, et aura comme
              thème un masterclass sur la réhabilitation améliorée après chirurgie, en
              partenariat avec le groupe francophone de réhabilitation améliorée après
              chirurgie (GRACE).
            </p>
            La 2ème journée, quant à elle, aura lieu le 11 Mars 2023, au centre des
            conférences, à l’hôtel Hyatt Regency de Casablanca, avec un programme
            riche et diversifié, autour de la médecine péri-opératoire. Seront au
            rendez-vous d’éminents conférenciers nationaux et internationaux et
            qui aborderont les différents aspects de la prise en charge péri-opératoire
            optimisée des patients.<p>
            Cette édition s’inscrit dans la vision habituelle des journées Casablanca
            ises, autour de la médecine péri-opératoire, et vise à renforcer les
            connaissances et les échanges d’expériences autour de cette thématique.
            Notre débat vise à prévenir la morbi-mortalité péri-opératoire, qui peut
            affecter surtout les patients fragiles. </p>
            <div class="parent">
              <div class="line1" style="color:#3e90c0">Lire la suite</div>
              <div class="line2">
                C’est bien pour cela que l’action du
            médecin anesthésiste réanimateur doit dépasser la salle opératoire et
            commencer dès la période préopératoire, afin d’évaluer et préparer les
            patients à l’intervention, et s’étendre au post-opératoire où son action,
            dans les services de chirurgie, en réanimation, est primordiale.
                <br>
            Nous vous attendons donc toutes et tous nombreux à accepter notre
            invitation pour participer à cet événement scientifique autour de la
            médecine péri-opératoire et la réhabilitation améliorée après chirurgie.
                <p>
                </p>
                <div class="centre"> Pour l’AMARC</div>
                <div class="centre1"><b></b></div>
                <div class="centre2"><b>La Présidente Pr. Afak NSIRI</b></div>
              </div>
            </div>
          </div>
          <div class="demo-image">
            <img src="/affiche.jpg" alt="demo image" />
          </div>
        </div>
      </section>
    </main>
  </div>
</template>

<script>
export default {
  name: " ",
  props: {
    msg: String,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
body,
html {
  height: 100%;
}
.demo {
  padding: 3rem 0rem;
  background-color: #228e7e;
}
.demo .container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  /* gap: 1rem; */
  align-items: center;
  color: #ffffff;
}
.demo-title {
  position: relative;
  right: 100px;
  flex: 1;
  writing-mode: vertical-rl;
  text-orientation: mixed;
  text-align: center;
  font-size: 3rem;
  transform: rotate(180deg);
}
.demo-main {
  flex: 4;
  color: #000;
  background-color: #ffffff;
  text-align: justify;
  border: 6px solid #f89434;
  border-radius: 2rem;
  padding: 2rem;
  width: 400px;
  position: relative;
  left: 50px;
}
.demo-image {
  flex: 2;
  display: inline-block;
  /* width: 100%; */
}

.demo-image img {
  height: 600px;
  object-fit: contain;
  position: relative;
  left: 100px;
}
.line2 {
  display: none;
}
.line1 {
  position: relative;
  left: 200px;
}
.parent:hover .line1 {
  display: none;
}
.parent:hover .line2 {
  display: inline;
}
.centre {
  position: relative;
  left: 140px;
}
.centre1 {
  position: relative;
  left: 170px;
}
.centre2 {
  position: relative;
  left: 90px;
}
@media (max-width: 500px) {
  .demo .container {
    flex-direction: column;
  }
  .demo-title {
    flex: 1;
    position: relative;
    top: -30px;
    right: 0px;
    writing-mode: horizontal-tb;
    text-align: center;
    font-size: 3rem;
    transform: rotate(0deg);
  }
  .demo-main {
    flex: 4;
    color: #000;
    background-color: #ffffff;
    text-align: justify;
    border: 6px solid #f89434;
    border-radius: 2rem;
    padding: 2rem;
    position: relative;
    left: 0px;
  }
  .demo-image img {
    height: 600px;
    object-fit: contain;
    position: relative;
    left: -0px;
  }
  .centre {
    position: relative;
    left: 150px;
  }
}
</style>
