import Vue from 'vue';
import VueRouter from 'vue-router';
import index from './components/index';
import indexvod from './components/indexvod';
import jour1 from './components/landing/jour1';
import jour2 from './components/landing/jour2';
import jour3 from './components/landing/jour3';
//import ExampleComponent from './components/ExampleComponent';
Vue.use(VueRouter);

export default new VueRouter({
        mode:'history',
        routes:[
            {
                path:'/',
                redirect: '/landingpage/jour1'
            },
            {
                path:'/landingpage',
                component:index,
                children: [
                    {
                      path: 'jour1',
                      component: jour1
                    },
                    {
                      path: 'jour2',
                      component: jour2
                    },
                    {
                        path: 'jour3',
                        component: jour3
                    },
                    {
                        path: '/',
                        redirect: 'jour1'
                    },
                  ]
            },
            {
                path:'/stream',
                component:indexvod,
            },
            {   path: '*',
                redirect: '/landingpage/jour1'
            }
            
        ]
});
