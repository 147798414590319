<template>
  <section class="download">
    <div class="title-sm-visibilty download-title-sm">
      <h4>PROGRAMME</h4>
      <h4>DETAILLÉ</h4>
    </div>
    <div class="container">
      <!-- <div class="img-left">
        <img src="../assets/download-img-left.png" alt="download margin left" />
      </div> -->
      <div class="card">
        <div class="card-main">
          <div class="title-lg-visibilty download-title-lg">
            <h1><b>PROGRAMME</b></h1>
            <h1><b>DETAILLÉ</b></h1>
          </div>
          <p>
            <b>Pour consulter ou télécharger le programme veuillez
            cliquer sur le bouton "Télécharger".</b>
          </p>
          <div class="download-button">
            <a href="https://drive.google.com/file/d/1f_dzoFyZaeKdBPBFimD-Eas-B17E3ctF/view?usp=share_link">
              <div class="button-prefix">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="50px"
                  height="50px"
                  fill="#f6933f"
                  stroke="white"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="feather feather-arrow-down-circle"
                >
                  <circle cx="12" cy="12" r="10"></circle>
                  <polyline points="8 12 12 16 16 12"></polyline>
                  <line x1="12" y1="8" x2="12" y2="16"></line>
                </svg>
              </div>Télécharger</a
            >
          </div>
        </div>
        <div class="card-img">
          <img src="/prog.png" alt="" />
        </div>
      </div>
      <!-- <div class="img-right">
        <img
          src="../assets/download-img-right.png"
          alt="download margin right"
        />
      </div> -->
    </div>
  </section>
</template>

<script>
export default {
  name: "FooterSection",
  props: {
    msg: String,
  },
};
</script>

<style scoped>
.download .container {
  display: flex;
  justify-content: center;
  overflow-x: hidden;
}
.img-left img {
  display: inline-block;
  height: 400px;
  position: absolute;
  left: -100px;
  transform: rotate(-30deg);
}
.img-right {
  position: relative;
}
.img-right img {
  display: inline-block;
  height: 400px;
}
.card {
  display: flex;
  border-radius: 0.75rem;
  background-color: rgba(23, 23, 250, 0.3);
  background-image: url("/back_sp.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.card-main {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 2rem;
}
.download-title-sm {
  margin: 1rem 0;
}
.download-title-sm h4 {
  margin: 0px;
  font-size: 3rem;
  font-weight: 600;
  text-align: center;
  color: #228e7e;
}
.download-title-lg h1 {
  margin: 0px;
  margin-bottom: 0.5rem;
  font-size: 3rem;
  font-weight: 500;
  text-align: start;
  color: #ffffff;
}
.card-main p {
  color: #fff;
  text-align: start;
  font-size: 1.5rem;
  letter-spacing: 2px;
  margin-bottom: 2rem;
}
.download-button {
  display: flex;
  position: relative;
}
.download-button a {
  background-color: #fff;
  border-radius: 0 1.5rem 0 0;
  padding: 0.6rem;
  padding-left: 2rem;
  margin-left: 1rem;
  text-decoration: none;
  display: flex;
  align-items: flex-end;
  align-items: center;
}
.download-button svg {
  position: absolute;
  right: 95px;
  top: -6px;
}
.card-img {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}
.card-img img {
  padding: 1rem 0rem;
  display: block;
  height: 400px;
}
.title-sm-visibilty {
  display: none;
}
@media (max-width: 768px) {
  .card {
    flex-direction: column;
  }
  .title-lg-visibilty {
    display: none;
  }
  .title-sm-visibilty {
    display: block;
  }
  .download-button {
    align-self: center;
  }

}
</style>

