<template >
    <v-app class="agendaback">
        <v-container>
        <v-card>
            <div>
                <div class="p-2">
                    <v-img  src="logo.png"  max-width="300" ></v-img>
                </div>
                <div class="date p-2">
                    <strong>SAMEDI 26 FÉVRIER 2022</strong>
                </div>
                <v-timeline align-top dense class="timeline">
                    <v-timeline-item color="blue" small>
                        <v-row class="pt-1 timelineprog">
                            <v-col cols="8">
                                <div class="heurire p-1">09H30 - 11H00</div>
                            </v-col>
                            <v-col cols="12">    
                                <v-row>
                                    <v-col cols="12">
                                        <div>
                                            <span style="color:#E4A837"> Track 1 </span><span style="color:#E4A837" class="sName">Conférences Updates III</span>
                                            <br>
                                            <span style="color:#204563;white-space: pre-line;" class="sName">Simulation en santé et recherche translationnelle</span><br>
                                            <a href="#speakers" @click="slideTo(13)" style="text-decoration:none">
                                                <span class="ml-2 orateurs">A. Hilali, Settat, Maroc |</span>
                                            </a><br>
                                            <span style="color:#204563;white-space: pre-line;" class="sName">Simulation numérique : apports et enjeux pour la formation des professionnels de la santé</span><br>
                                            <a href="#speakers" @click="slideTo(13)" style="text-decoration:none">
                                                <span class="ml-2 orateurs"> M. Abbadi, Montréal, Canada |</span>
                                            </a><br>
                                            <span style="color:#204563;white-space: pre-line;" class="sName">Défis scientifiques de la simulation en 2022</span><br>
                                            <a href="#speakers" @click="slideTo(13)" style="text-decoration:none">
                                                <span class="ml-2 orateurs"> D. Benhamou, Paris, France</span>
                                            </a><br>
                                            <hr class="hr3">
                                        </div>
                                        <div>
                                            <span style="color:#E4A837"> Track 2 </span><span style="color:#E4A837" class="sName">Biomedical</span>
                                            <br>
                                            <span style="color:#204563;white-space: pre-line;" class="sName">Curriculum innovation biomédicale Part I</span><br>
                                            <a href="#speakers" @click="slideTo(13)" style="text-decoration:none">
                                                <span class="ml-2 orateurs">A. Loubariz, Tanger, Maroc |</span>
                                            </a>
                                            <a href="#speakers" @click="slideTo(13)" style="text-decoration:none">
                                                <span class="ml-2 orateurs"> M. Nabil, Casablanca, Maroc |</span>
                                            </a>
                                            <a href="#speakers" @click="slideTo(13)" style="text-decoration:none">
                                                <span class="ml-2 orateurs"> K. Zdeg, Marrakech, Maroc</span>
                                            </a>
                                            <hr class="hr3">
                                        </div>
                                        <div>
                                            <span style="color:#E4A837"> Track 3 </span><span style="color:#E4A837" class="sName">Procedural</span>
                                            <br>
                                            <span style="color:#204563;white-space: pre-line;" class="sName">Suture</span><br>
                                            <a href="#speakers" @click="slideTo(13)" style="text-decoration:none">
                                                <span class="ml-2 orateurs">A. Rezziki, Oujda, Maroc</span>
                                            </a>
                                            <hr class="hr3">
                                        </div>
                                        <div>
                                            <span style="color:#E4A837"> Track 4 </span><span style="color:#E4A837" class="sName">BLS Certification</span>
                                            <br>
                                            <span style="color:#204563;white-space: pre-line;" class="sName">Basic life support AHA certification Part I</span><br>
                                            <a href="#speakers" @click="slideTo(13)" style="text-decoration:none">
                                                <span class="ml-2 orateurs">AR. El Adib, Marrakech, Maroc |</span>
                                            </a>
                                            <a href="#speakers" @click="slideTo(13)" style="text-decoration:none">
                                                <span class="ml-2 orateurs"> A. Mounji, Casablanca, Maroc |</span>
                                            </a>
                                            <a href="#speakers" @click="slideTo(13)" style="text-decoration:none">
                                                <span class="ml-2 orateurs"> M. Insaf, Casablanca, Maroc</span>
                                            </a>
                                            <hr class="hr3">
                                        </div>
                                        <div>
                                            <span style="color:#E4A837"> Track 5 </span><span style="color:#E4A837" class="sName">Interprofessionnel</span>
                                            <br>
                                            <span style="color:#204563;white-space: pre-line;" class="sName">Curriculum Infirmiers polyvalents (Soins infirmiers pour tous) Part I</span><br>
                                            <a href="#speakers" @click="slideTo(13)" style="text-decoration:none">
                                                <span class="ml-2 orateurs">J. Bougalala, Bruxelles, Belgique |</span>
                                            </a>
                                            <a href="#speakers" @click="slideTo(13)" style="text-decoration:none">
                                                <span class="ml-2 orateurs"> B. Ammar, Marrakech, Maroc |</span>
                                            </a>
                                            <a href="#speakers" @click="slideTo(13)" style="text-decoration:none">
                                                <span class="ml-2 orateurs"> S. El Hamdaoui, Tanger, Maroc</span>
                                            </a>
                                            <hr class="hr3">
                                        </div>
                                        <div>
                                            <span style="color:#E4A837"> Track 6 </span><span style="color:#E4A837" class="sName">Haptic</span>
                                            <br>
                                            <span style="color:#204563;white-space: pre-line;" class="sName">Curriculum Chirurgie de la cataracte Part I</span><br>
                                            <a href="#speakers" @click="slideTo(13)" style="text-decoration:none">
                                                <span class="ml-2 orateurs">A. Mchachi, Casablanca, Maroc |</span>
                                            </a>
                                            <a href="#speakers" @click="slideTo(13)" style="text-decoration:none">
                                                <span class="ml-2 orateurs"> M. Bahloul, Tanger, Maroc</span>
                                            </a>
                                            <hr class="hr3">
                                        </div>
                                        <div>
                                            <span style="color:#E4A837"> Track 7 </span><span style="color:#E4A837" class="sName">Ultrasound</span>
                                            <br>
                                            <span style="color:#204563;white-space: pre-line;" class="sName">Curriculum Échographie pulmonaire Part I</span><br>
                                            <a href="#speakers" @click="slideTo(13)" style="text-decoration:none">
                                                <span class="ml-2 orateurs">M. Bensghir, Rabat, Maroc |</span>
                                            </a>
                                            <a href="#speakers" @click="slideTo(13)" style="text-decoration:none">
                                                <span class="ml-2 orateurs"> T. Nebhani, Rabat, Maroc</span>
                                            </a>
                                            <hr class="hr3">
                                        </div>
                                        <div>
                                            <span style="color:#E4A837"> Track 8 </span><span style="color:#E4A837" class="sName">Sim Galaxy 360°</span>
                                            <br>
                                            <span style="color:#204563;white-space: pre-line;" class="sName">Scenarii CRM Réanimation Part I</span><br>
                                            <a href="#speakers" @click="slideTo(13)" style="text-decoration:none">
                                                <span class="ml-2 orateurs">T. Dendane, Rabat, Maroc |</span>
                                            </a>
                                            <a href="#speakers" @click="slideTo(13)" style="text-decoration:none">
                                                <span class="ml-2 orateurs"> J. Tadili, Rabat, Maroc |</span>
                                            </a>
                                            <a href="#speakers" @click="slideTo(13)" style="text-decoration:none">
                                                <span class="ml-2 orateurs"> W. El Machtani El idrissi, Casablanca, Maroc</span>
                                            </a>
                                            <hr class="hr3">
                                        </div>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                    </v-timeline-item>

                    <v-timeline-item color="blue" small>
                        <v-row class="pt-1 timelineprog">
                            <v-col cols="8">
                                <div class="heurire p-1">11H30 - 13H00</div>
                            </v-col>
                            <v-col cols="12">    
                                <div>
                                    <span style="color:#E4A837"> Track 1 </span><span style="color:#E4A837" class="sName">Sim d’Or</span>
                                    <br>
                                    <span style="color:#204563;white-space: pre-line;" class="sName">Concours des meilleurs travaux de recherche</span><br>
                                    <a href="#speakers" @click="slideTo(13)" style="text-decoration:none">
                                        <span class="ml-2 orateurs">Orateur 1</span>
                                    </a>
                                    <hr class="hr3">
                                </div>
                                <div>
                                    <span style="color:#E4A837"> Track 2 </span><span style="color:#E4A837" class="sName">Biomedical</span>
                                    <br>
                                    <span style="color:#204563;white-space: pre-line;" class="sName">Curriculum innovation biomédicale Part II</span><br>
                                    <a href="#speakers" @click="slideTo(13)" style="text-decoration:none">
                                        <span class="ml-2 orateurs">A. Loubariz, Tanger, Maroc</span>
                                    </a>
                                    <a href="#speakers" @click="slideTo(13)" style="text-decoration:none">
                                        <span class="ml-2 orateurs"> M. Nabil, Casablanca, Maroc |</span>
                                    </a>
                                    <a href="#speakers" @click="slideTo(13)" style="text-decoration:none">
                                        <span class="ml-2 orateurs"> K. Zdeg, Marrakech, Maroc</span>
                                    </a>
                                    <hr class="hr3">
                                </div>
                                <div>
                                    <span style="color:#E4A837"> Track 3 </span><span style="color:#E4A837" class="sName">Procedural</span>
                                    <br>
                                    <span style="color:#204563;white-space: pre-line;" class="sName">Plâtre</span><br>
                                    <a href="#speakers" @click="slideTo(13)" style="text-decoration:none">
                                        <span class="ml-2 orateurs">M. Shimi, Tanger, Maroc |</span>
                                    </a>
                                    <a href="#speakers" @click="slideTo(13)" style="text-decoration:none">
                                        <span class="ml-2 orateurs"> H. Ait Benali, Tanger, Maroc</span>
                                    </a>
                                    <hr class="hr3">
                                </div>
                                <div>
                                    <span style="color:#E4A837"> Track 4 </span><span style="color:#E4A837" class="sName">BLS Certification</span>
                                    <br>
                                    <span style="color:#204563;white-space: pre-line;" class="sName">Basic life support AHA certification Part II</span><br>
                                    <a href="#speakers" @click="slideTo(13)" style="text-decoration:none">
                                        <span class="ml-2 orateurs">AR. El Adib, Marrakech, Maroc |</span>
                                    </a>
                                    <a href="#speakers" @click="slideTo(13)" style="text-decoration:none">
                                        <span class="ml-2 orateurs"> A. Mounji, Casablanca, Maroc |</span>
                                    </a>
                                    <a href="#speakers" @click="slideTo(13)" style="text-decoration:none">
                                        <span class="ml-2 orateurs"> M. Insaf, Casablanca, Maroc</span>
                                    </a>
                                    <hr class="hr3">
                                </div>
                                <div>
                                    <span style="color:#E4A837"> Track 5 </span><span style="color:#E4A837" class="sName">Interprofessionnel</span>
                                    <br>
                                    <span style="color:#204563;white-space: pre-line;" class="sName">Curriculum Infirmiers polyvalents (Soins infirmiers pour tous) Part II</span><br>
                                    <a href="#speakers" @click="slideTo(13)" style="text-decoration:none">
                                        <span class="ml-2 orateurs">J. Bougalala, Bruxelles, Belgique |</span>
                                    </a>
                                    <a href="#speakers" @click="slideTo(13)" style="text-decoration:none">
                                        <span class="ml-2 orateurs"> B. Ammar, Marrakech, Maroc |</span>
                                    </a>
                                    <a href="#speakers" @click="slideTo(13)" style="text-decoration:none">
                                        <span class="ml-2 orateurs"> S. El Hamdaoui, Tanger, Maroc</span>
                                    </a>
                                    <hr class="hr3">
                                </div>
                                <div>
                                    <span style="color:#E4A837"> Track 6 </span><span style="color:#E4A837" class="sName">Haptic</span>
                                    <br>
                                    <span style="color:#204563;white-space: pre-line;" class="sName">Curriculum Chirurgie de la cataracte Part II</span><br>
                                    <a href="#speakers" @click="slideTo(13)" style="text-decoration:none">
                                        <span class="ml-2 orateurs">A. Mchachi, Casablanca, Maroc |</span>
                                    </a>
                                    <a href="#speakers" @click="slideTo(13)" style="text-decoration:none">
                                        <span class="ml-2 orateurs"> M. El Bahloul, Tanger, Maroc</span>
                                    </a>
                                    <hr class="hr3">
                                </div>
                                <div>
                                    <span style="color:#E4A837"> Track 7 </span><span style="color:#E4A837" class="sName">Ultrasound</span>
                                    <br>
                                    <span style="color:#204563;white-space: pre-line;" class="sName">Curriculum Échographie pulmonaire Part II</span><br>
                                    <a href="#speakers" @click="slideTo(13)" style="text-decoration:none">
                                        <span class="ml-2 orateurs">M. Bensghir, Rabat, Maroc |</span>
                                    </a>
                                    <a href="#speakers" @click="slideTo(13)" style="text-decoration:none">
                                        <span class="ml-2 orateurs"> T. Nebhani, Rabat, Maroc</span>
                                    </a>
                                    <hr class="hr3">
                                </div>
                                <div>
                                    <span style="color:#E4A837"> Track 8 </span><span style="color:#E4A837" class="sName">Sim Galaxy 360°</span>
                                    <br>
                                    <span style="color:#204563;white-space: pre-line;" class="sName">Scenarii CRM Réanimation Part II</span><br>
                                    <a href="#speakers" @click="slideTo(13)" style="text-decoration:none">
                                        <span class="ml-2 orateurs">T. Dendane, Rabat, Maroc |</span>
                                    </a>
                                    <a href="#speakers" @click="slideTo(13)" style="text-decoration:none">
                                        <span class="ml-2 orateurs"> J. Tadili, Rabat, Maroc |</span>
                                    </a>
                                    <a href="#speakers" @click="slideTo(13)" style="text-decoration:none">
                                        <span class="ml-2 orateurs"> W. El Machtani El idrissi, Casablanca, Maroc</span>
                                    </a>
                                    <hr class="hr3">
                                </div>
                            </v-col>
                        </v-row>
                    </v-timeline-item>

                    <v-timeline-item color="blue" small>
                        <v-row class="pt-1 timelineprog">
                            <v-col cols="8">
                                <div class="heurire p-1">14H30 - 16H00</div>
                            </v-col>
                            <v-col cols="12">    
                               <div>
                                    <span style="color:#E4A837"> Track 1 </span><span style="color:#E4A837" class="sName">Masterclass</span>
                                    <br>
                                    <span style="color:#204563;white-space: pre-line;" class="sName">Pédagogie et enseignement par simulation en santé Part V</span><br>
                                    <a href="#speakers" @click="slideTo(13)" style="text-decoration:none">
                                        <span class="ml-2 orateurs">B. Boukatta, Fès, Maroc |</span>
                                    </a>
                                    <a href="#speakers" @click="slideTo(13)" style="text-decoration:none">
                                        <span class="ml-2 orateurs"> H. Nassik, Agadir, Maroc |</span>
                                    </a>
                                    <a href="#speakers" @click="slideTo(13)" style="text-decoration:none">
                                        <span class="ml-2 orateurs"> A. Bentalha, Rabat, Maroc |</span>
                                    </a>
                                    <a href="#speakers" @click="slideTo(13)" style="text-decoration:none">
                                        <span class="ml-2 orateurs"> H. Sbai, Tanger, Maroc|</span>
                                    </a>
                                    <hr class="hr3">
                                </div>
                                <div>
                                    <span style="color:#E4A837"> Track 2 </span><span style="color:#E4A837" class="sName">Intelligence artificielle</span>
                                    <br>
                                    <span style="color:#204563;white-space: pre-line;" class="sName">Curriculum intelligence artificielle Part I</span><br>
                                     <a href="#speakers" @click="slideTo(13)" style="text-decoration:none">
                                        <span class="ml-2 orateurs">N. Zary, Dubai, Émirates Arabes Unies</span>
                                    </a>
                                    <hr class="hr3">
                                </div>
                                <div>
                                    <span style="color:#E4A837"> Track 3 </span><span style="color:#E4A837" class="sName">Gamification</span>
                                    <br>
                                    <span style="color:#204563;white-space: pre-line;" class="sName">Ludification pédagogique Part I</span><br>
                                    <a href="#speakers" @click="slideTo(13)" style="text-decoration:none">
                                        <span class="ml-2 orateurs">M. Fourtassi, Tanger, Maroc</span>
                                    </a>
                                    <hr class="hr3">
                                </div>
                                <div>
                                    <span style="color:#E4A837"> Track 4 </span><span style="color:#E4A837" class="sName">Procedural</span>
                                    <br>
                                    <span style="color:#204563;white-space: pre-line;" class="sName">Simulateur ALR Part I</span><br>
                                    <a href="#speakers" @click="slideTo(13)" style="text-decoration:none">
                                        <span class="ml-2 orateurs">R. Al Harrar, Casablanca, Maroc |</span>
                                    </a>
                                    <a href="#speakers" @click="slideTo(13)" style="text-decoration:none">
                                        <span class="ml-2 orateurs"> A. Nsiri, Casablanca, Maroc</span>
                                    </a>
                                    <hr class="hr3">
                                </div>
                                <div>
                                    <span style="color:#E4A837"> Track 5 </span><span style="color:#E4A837" class="sName">Interprofessionnel</span>
                                    <br>
                                    <span style="color:#204563;white-space: pre-line;" class="sName">Curriculum annonce et éducation thérapeutique du patient diabétique Part I</span><br>
                                    <a href="#speakers" @click="slideTo(13)" style="text-decoration:none">
                                        <span class="ml-2 orateurs">R. Benjelloun, Casablanca, Maroc |</span>
                                    </a>
                                    <a href="#speakers" @click="slideTo(13)" style="text-decoration:none">
                                        <span class="ml-2 orateurs"> I. Motaib, Casablanca, Maroc</span>
                                    </a>
                                    <hr class="hr3">
                                </div>
                                <div>
                                    <span style="color:#E4A837"> Track 6 </span><span style="color:#E4A837" class="sName">Haptic</span>
                                    <br>
                                    <span style="color:#204563;white-space: pre-line;" class="sName">Curriculum Arthroscopie de l’épaule et du genou Part I</span><br>
                                    <a href="#speakers" @click="slideTo(13)" style="text-decoration:none">
                                        <span class="ml-2 orateurs">K. Rafiqi, Agadir, Maroc |</span>
                                    </a>
                                    <a href="#speakers" @click="slideTo(13)" style="text-decoration:none">
                                        <span class="ml-2 orateurs"> M. Chehbouni, Casablanca, Maroc</span>
                                    </a>
                                    <hr class="hr3">
                                </div>
                                <div>
                                    <span style="color:#E4A837"> Track 7 </span><span style="color:#E4A837" class="sName">Ultrasound</span>
                                    <br>
                                    <span style="color:#204563;white-space: pre-line;" class="sName">Curriculum Doppler transcrânien Part I</span><br>
                                    <a href="#speakers" @click="slideTo(13)" style="text-decoration:none">
                                        <span class="ml-2 orateurs">N. Touil, Casablanca, Maroc |</span>
                                    </a>
                                    <a href="#speakers" @click="slideTo(13)" style="text-decoration:none">
                                        <span class="ml-2 orateurs"> H. Tabakh, Casablanca, Maroc</span>
                                    </a>
                                    <hr class="hr3">
                                </div>
                                <div>
                                    <span style="color:#E4A837"> Track 8 </span><span style="color:#E4A837" class="sName">Sim Galaxy 360°</span>
                                    <br>
                                    <span style="color:#204563;white-space: pre-line;" class="sName">Scenarii CRM Anesthésiologie Part I</span><br>
                                    <a href="#speakers" @click="slideTo(13)" style="text-decoration:none">
                                        <span class="ml-2 orateurs">A. Baite, Rabat, Maroc |</span>
                                    </a>
                                    <a href="#speakers" @click="slideTo(13)" style="text-decoration:none">
                                        <span class="ml-2 orateurs"> S. Fjouji, Rabat, Maroc FZ |</span>
                                    </a>
                                    <a href="#speakers" @click="slideTo(13)" style="text-decoration:none">
                                        <span class="ml-2 orateurs"> Mahou, Rabat, Maroc</span>
                                    </a>
                                    <hr class="hr3">
                                </div>
                            </v-col>
                        </v-row>
                    </v-timeline-item>

                    <v-timeline-item color="blue" small>
                        <v-row class="pt-1 timelineprog">
                            <v-col cols="8">
                                <div class="heurire p-1">16H30 - 18H00</div>
                            </v-col>
                            <v-col cols="12">    
                                <div>
                                    <span style="color:#E4A837"> Track 1 </span><span style="color:#E4A837" class="sName">Masterclass</span>
                                    <br>
                                    <span style="color:#204563;white-space: pre-line;" class="sName">Pédagogie et enseignement par simulation en santé Part VI</span><br>
                                    <a href="#speakers" @click="slideTo(13)" style="text-decoration:none">
                                        <span class="ml-2 orateurs">B. Boukatta, Fès, Maroc |</span>
                                    </a>
                                    <a href="#speakers" @click="slideTo(13)" style="text-decoration:none">
                                        <span class="ml-2 orateurs"> H. Nassik, Agadir, Maroc |</span>
                                    </a>
                                    <a href="#speakers" @click="slideTo(13)" style="text-decoration:none">
                                        <span class="ml-2 orateurs"> A. Bentalha, Rabat, Maroc |</span>
                                    </a>
                                    <a href="#speakers" @click="slideTo(13)" style="text-decoration:none">
                                        <span class="ml-2 orateurs"> H. Sbai, Tanger, Maroc</span>
                                    </a>
                                    <hr class="hr3">
                                </div>
                                <div>
                                    <span style="color:#E4A837"> Track 2 </span><span style="color:#E4A837" class="sName">Intelligence artificielle</span>
                                    <br>
                                    <span style="color:#204563;white-space: pre-line;" class="sName">Curriculum intelligence artificielle Part II</span><br>
                                    <a href="#speakers" @click="slideTo(13)" style="text-decoration:none">
                                        <span class="ml-2 orateurs">N. Zary, Dubai, Émirates Arabes Unies</span>
                                    </a>
                                    <hr class="hr3">
                                </div>
                                <div>
                                    <span style="color:#E4A837"> Track 3 </span><span style="color:#E4A837" class="sName">Gamification</span>
                                    <br>
                                    <span style="color:#204563;white-space: pre-line;" class="sName">Ludification pédagogique Part II</span><br>
                                    <a href="#speakers" @click="slideTo(13)" style="text-decoration:none">
                                        <span class="ml-2 orateurs">M. Fourtassi, Tanger, Maroc</span>
                                    </a>
                                    <hr class="hr3">
                                </div>
                                <div>
                                    <span style="color:#E4A837"> Track 4 </span><span style="color:#E4A837" class="sName">Procedural</span>
                                    <br>
                                    <span style="color:#204563;white-space: pre-line;" class="sName">Simulateur ALR Part II</span><br>
                                    <a href="#speakers" @click="slideTo(13)" style="text-decoration:none">
                                        <span class="ml-2 orateurs">R. Al Harrar, Casablanca, Maroc |</span>
                                    </a>
                                    <a href="#speakers" @click="slideTo(13)" style="text-decoration:none">
                                        <span class="ml-2 orateurs"> A. Nsiri, Casablanca, Maroc</span>
                                    </a>
                                    <hr class="hr3">
                                </div>
                                <div>
                                    <span style="color:#E4A837"> Track 5 </span><span style="color:#E4A837" class="sName">Interprofessionnel</span>
                                    <br>
                                    <span style="color:#204563;white-space: pre-line;" class="sName">Curriculum annonce et éducation thérapeutique du patient diabétique Part II</span><br>
                                    <a href="#speakers" @click="slideTo(13)" style="text-decoration:none">
                                        <span class="ml-2 orateurs">R. Benjelloun, Casablanca, Maroc |</span>
                                    </a>
                                    <a href="#speakers" @click="slideTo(13)" style="text-decoration:none">
                                        <span class="ml-2 orateurs"> I. Motaib, Casablanca, Maroc</span>
                                    </a>
                                    <hr class="hr3">
                                </div>
                                <div>
                                    <span style="color:#E4A837"> Track 6 </span><span style="color:#E4A837" class="sName">Haptic</span>
                                    <br>
                                    <span style="color:#204563;white-space: pre-line;" class="sName">Curriculum Arthroscopie de l’épaule et du genou Part II</span><br>
                                    <a href="#speakers" @click="slideTo(13)" style="text-decoration:none">
                                        <span class="ml-2 orateurs">K. Rafiqi, Agadir, Maroc |</span>
                                    </a>
                                    <a href="#speakers" @click="slideTo(13)" style="text-decoration:none">
                                        <span class="ml-2 orateurs"> M. Chehbouni, Casablanca, Maroc</span>
                                    </a>
                                    <hr class="hr3">
                                </div>
                                <div>
                                    <span style="color:#E4A837"> Track 7 </span><span style="color:#E4A837" class="sName">Ultrasound</span>
                                    <br>
                                    <span style="color:#204563;white-space: pre-line;" class="sName">Curriculum Doppler transcrânien Part II</span><br>
                                    <a href="#speakers" @click="slideTo(13)" style="text-decoration:none">
                                        <span class="ml-2 orateurs">N. Doghmi, Rabat, Maroc |</span>
                                    </a>
                                    <a href="#speakers" @click="slideTo(13)" style="text-decoration:none">
                                        <span class="ml-2 orateurs"> S. Zidouh, Rabat, Maroc</span>
                                    </a>
                                    <hr class="hr3">
                                </div>
                                <div>
                                    <span style="color:#E4A837"> Track 8 </span><span style="color:#E4A837" class="sName">Sim Galaxy 360°</span>
                                    <br>
                                    <span style="color:#204563;white-space: pre-line;" class="sName">Scenarii CRM Anesthésiologie Part II</span><br>
                                    <a href="#speakers" @click="slideTo(13)" style="text-decoration:none">
                                        <span class="ml-2 orateurs">A. Baite, Rabat, Maroc |</span>
                                    </a>
                                    <a href="#speakers" @click="slideTo(13)" style="text-decoration:none">
                                        <span class="ml-2 orateurs"> S. Fjouji, Rabat, Maroc |</span>
                                    </a>
                                    <a href="#speakers" @click="slideTo(13)" style="text-decoration:none">
                                        <span class="ml-2 orateurs"> FZ. Mahou, Rabat, Maroc</span>
                                    </a>
                                    <hr class="hr3">
                                </div>
                            </v-col>
                        </v-row>
                    </v-timeline-item>
                </v-timeline>
            </div>
        </v-card>
        </v-container>
    </v-app>
</template>

<script>
  export default {
    methods: {
        slideTo(num){
            this.$emit('gotoslide',num)
        }
    },
  }
</script>

<style>

</style>
