<template>
	<div>
	<nav style="background-color:white" class="navbar navbar-light navbar-expand-md bg-faded justify-content-center sticky-top ">
		<a href="#" class="d-flex  mr-auto">
		<img  class="img-responsive logonav" src="../assets/hticlogo.png"  >
		</a>
		<button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#collapsingNavbar3">
		<span class="navbar-toggler-icon"></span>
		</button>
		<div class="navbar-collapse collapse w-100" id="collapsingNavbar3">
			<ul class="nav navbar-nav  ml-auto justify-content-center first">
			<li>
				<a href="/"  >
					<b>ACCUEIL</b>
				</a>
			</li>
			</ul>
		</div>
	</nav>
	<v-container>
		<v-row class="mt-2 mb-5">
			<v-col cols="12" align="center">
				<v-img aspect-ratio="3.6" @click="imgdialog = true" class="img-responsive programvend" max-width="400px" src="../assets/glaxy.png" data-aos-once="true" data-aos="zoom-in" data-aos-duration="1500" >
				</v-img>
			</v-col>
		</v-row>
	</v-container>
	<v-container grid-list-xs>
	<v-row>
		<v-col align="center">
			<div  class="iframe-container p-1 " data-aos-once="true" data-aos="fade-up" data-aos-duration="1500">
				<iframe class="responsive-iframe p-1"  src="https://www.youtube-nocookie.com//embed/uKw1unbRlhQ"  frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
			</div>
		</v-col>
	</v-row>
	<hr class="hr3">
	<v-row class="mt-3">
		<v-col align="center">
			<div  class="iframe-container p-1 " data-aos-once="true" data-aos="fade-up" data-aos-duration="1500">
				<iframe class="responsive-iframe p-1"  src="https://www.youtube-nocookie.com//embed/AgBpgeSZPCQ"  frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
			</div>
		</v-col>
	</v-row>
	<hr class="hr3">
	<v-row class="mt-3">
		<v-col align="center">
			<div  class="iframe-container p-1 " data-aos-once="true" data-aos="fade-up" data-aos-duration="1500">
				<iframe class="responsive-iframe p-1"  src="https://www.youtube-nocookie.com//embed/s8-I0_m_7zw"  frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
			</div>
		</v-col>
	</v-row>
	</v-container>
	<nav style="background-color:#0F5288;height:50px" class="pa-2 ">
	<v-layout >
		<v-flex>
			<b style="color:white;" class="titleDate">Rendez-vous du <vue-typer text=' 24 AU 26 FEBRUARY 2022' ></vue-typer></b>
		</v-flex>
		<v-flex style="text-align:right">
			<a href="https://ascrea.ma/" target="_blank" style="text-decoration:none;" class="contactAdn titleDateAs ">Réalisé par ascrea.ma</a><br>
		</v-flex>
	</v-layout>
	</nav>
	</div>  
</template>
<script>
export default {
  data() {
    return {
        /* link:'', */
    }
  },
  methods: {
      /* getID(url){
                return url.match(/(?:youtube(?:-nocookie)?\.com\/(?:[^/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/)[1]
            },
      getData(){
            this.axios({
                    method: 'get',
                    url: 'https://htic2022mp.hashtagsante.ma/api/getlink'
                    }).then((rep)=>{                
                         this.link="https://www.youtube-nocookie.com//embed/"+this.getID(rep.data)+"?rel=0"
                    });
        } */
},
  mounted() {
  },
}
</script>
<style >
.responsive-iframe{
	border-radius: 5%;
	background-color: #0F5288;
}

.iframe-container{
        position: relative;
        overflow: hidden;
        width: 100%;
        margin-top: -10px;
        height: 600px;
        /* padding-top: 56.25%; */
    }
    .responsive-iframe {
        /* position: absolute; */
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        width: 100%;
        height: 100%;
    }
</style>